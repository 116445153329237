/* eslint-disable max-len */
import React from 'react';
//////////////////////////////////////////////////

const youtube = (color: any, width: any, height: any) => (
  <svg
    viewBox='0 0 50 36'
    width={width || 16}
    height={height || 12}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#fff'}
      d='M47.882 3.09C46.106.895 42.827 0 36.565 0h-22.73c-6.406 0-9.74.953-11.51 3.29C.6 5.568.6 8.926.6 13.572v8.856C.6 31.43 2.647 36 13.834 36h22.732c5.43 0 8.44-.79 10.386-2.727 1.996-1.987 2.848-5.23 2.848-10.845v-8.856c0-4.9-.133-8.277-1.918-10.482zM32.187 19.223l-10.323 5.609a1.537 1.537 0 0 1-1.557-.051 1.665 1.665 0 0 1-.765-1.412V12.187c0-.576.29-1.11.763-1.41s1.064-.32 1.556-.054l10.322 5.573a1.66 1.66 0 0 1 .856 1.462 1.66 1.66 0 0 1-.852 1.465z'
    />
  </svg>
);

export {
  youtube,
};
