/* eslint-disable max-len */
import React from 'react';
//////////////////////////////////////////////////

const excel = (color: string, width: any, height: any) => (
  <svg
    width={width || 22}
    height={height || 20}
    viewBox='0 0 22 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path fill='#eceff1' d='M21.313 17.143h-9.625c-.38 0-.688-.291-.688-.65V3.507c0-.358.308-.649.688-.649h9.624c.38 0 .688.291.688.65v12.987c0 .358-.308.649-.688.649z' />
    <path fill='#388e3c' d='M14.333 7.069h-2.666c-.368 0-.667-.303-.667-.677s.299-.678.667-.678h2.666c.368 0 .667.304.667.678s-.299.677-.667.677zm0 2.709h-2.666c-.368 0-.667-.303-.667-.677s.299-.678.667-.678h2.666c.368 0 .667.304.667.678s-.299.677-.667.677zm0 2.709h-2.666c-.368 0-.667-.303-.667-.677s.299-.678.667-.678h2.666c.368 0 .667.304.667.678s-.299.677-.667.677zm0 2.709h-2.666c-.368 0-.667-.303-.667-.677s.299-.678.667-.678h2.666c.368 0 .667.304.667.678s-.299.677-.667.677zm4-8.127H17c-.368 0-.667-.303-.667-.677s.299-.678.667-.678h1.333c.368 0 .667.304.667.678s-.299.677-.667.677zm0 2.709H17c-.368 0-.667-.303-.667-.677s.299-.678.667-.678h1.333c.368 0 .667.304.667.678s-.299.677-.667.677zm0 2.709H17c-.368 0-.667-.303-.667-.677s.299-.678.667-.678h1.333c.368 0 .667.304.667.678s-.299.677-.667.677zm0 2.709H17c-.368 0-.667-.303-.667-.677s.299-.678.667-.678h1.333c.368 0 .667.304.667.678s-.299.677-.667.677z' />
    <path fill='#2e7d32' d='M11.759.154c-.152-.126-.356-.181-.548-.141l-10.667 2C.228 2.07 0 2.346 0 2.667v14.666c0 .32.228.596.544.655l10.667 2c.04.008.081.012.122.012.155 0 .306-.053.426-.153a.67.67 0 0 0 .241-.514V.668c0-.2-.088-.387-.241-.514z' />
    <path fill='#fafafa' d='M9.835 12.185L7.727 9.68l2.132-2.848c.227-.302.173-.737-.116-.973a.65.65 0 0 0-.936.121L6.832 8.619 5.17 6.643c-.244-.29-.666-.317-.94-.065a.71.71 0 0 0-.063.977L6 9.733l-1.858 2.482c-.227.302-.174.737.116.973.122.098.268.145.41.145.199 0 .395-.091.527-.267l1.7-2.272 1.938 2.301a.65.65 0 0 0 .502.238c.156 0 .312-.056.438-.171a.71.71 0 0 0 .063-.977z' />
  </svg>
);

const eye = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 16 15'
    width={width || 16}
    height={height || 15}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#015295'}
      d='M14.96 7.355c-.7-1.195-1.754-2.201-3.045-2.91A9.19 9.19 0 0 0 7.5 3.332a9.19 9.19 0 0 0-4.415 1.111C1.794 5.154.74 6.16.04 7.355a.286.286 0 0 0 0 .29c.701 1.195 1.755 2.201 3.046 2.91A9.19 9.19 0 0 0 7.5 11.668a9.19 9.19 0 0 0 4.415-1.111c1.291-.71 2.345-1.716 3.046-2.911a.287.287 0 0 0 0-.29zM.626 7.5c.926-1.492 2.412-2.601 4.161-3.168a4.152 4.152 0 0 0 0 6.336C3.037 10.101 1.551 8.992.626 7.5zM7.5 11.092A3.603 3.603 0 0 1 3.894 7.5a3.606 3.606 0 1 1 7.212 0A3.603 3.603 0 0 1 7.5 11.092zm2.714-.424a4.152 4.152 0 0 0 0-6.336c1.75.567 3.235 1.676 4.161 3.168-.926 1.492-2.412 2.601-4.161 3.168zm-.075-3.446a.277.277 0 0 0-.278.277V7.5c0 .153.124.277.278.277a.277.277 0 0 0 .278-.277h0a.277.277 0 0 0-.278-.277zm.122-.727a2.918 2.918 0 0 0-2.75-1.912c-.16 0-.289.128-.289.285s.13.285.29.285a2.34 2.34 0 0 1 2.203 1.532.29.29 0 0 0 .37.174.284.284 0 0 0 .176-.364zM2.904 0H.29A.29.29 0 0 0 0 .29v2.614a.29.29 0 1 0 .58 0V.581h2.324a.291.291 0 0 0 0-.581zm0 14.42H.581v-2.324a.291.291 0 0 0-.581 0v2.614a.29.29 0 0 0 .29.29h2.614a.29.29 0 0 0 0-.58zM14.779 0h-2.614a.29.29 0 0 0 0 .58h2.324v2.324a.29.29 0 0 0 .58 0V.29a.29.29 0 0 0-.29-.29zm0 11.806a.29.29 0 0 0-.29.29v2.323h-2.324a.29.29 0 0 0 0 .581h2.614a.29.29 0 0 0 .29-.29v-2.614a.29.29 0 0 0-.29-.29z'
    />
  </svg>
);

const eye2 = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 14 14'
    width={width || 14}
    height={height || 14}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#fff'}
      d='M6.563 2.354C4.214 2.494 2.037 3.82.591 5.996l-.424.695L0 7l.167.306c1.184 2.179 3.161 3.73 5.357 4.2a7.36 7.36 0 0 0 2.953 0c1.955-.418 3.711-1.668 4.933-3.503l.424-.697L14 7l-.167-.309c-.85-1.561-2.155-2.849-3.628-3.585a7.07 7.07 0 0 0-3.642-.752zm.998 1.791c.883.17 1.638.744 2.043 1.556A2.73 2.73 0 0 1 9.905 7a2.81 2.81 0 0 1-.785 1.993c-.571.602-1.288.911-2.119.911-1.408 0-2.565-.949-2.857-2.343-.06-.29-.06-.831 0-1.121.12-.569.358-1.031.738-1.433a2.59 2.59 0 0 1 .733-.563 2.84 2.84 0 0 1 1.947-.298zM6.62 5.291a1.79 1.79 0 0 0-1.173.919 1.7 1.7 0 0 0 0 1.58c.224.454.686.812 1.187.919 1.233.263 2.338-.842 2.075-2.075A1.77 1.77 0 0 0 6.62 5.291z'
    />
  </svg>
);

const eyeHide = (color: any, width: any, height: any) => (
  <svg
    viewBox='0 0 32 32'
    width={width || 32}
    height={height || 32}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      fill='#fff'
      strokeWidth='2'
      stroke={color || '#015295'}
    >
      <path d='M16 7C9.934 7 4.798 10.776 3 16c1.798 5.224 6.934 9 13 9s11.202-3.776 13-9c-1.798-5.224-6.934-9-13-9z' />
      <circle r='5' cx='16' cy='16' />
    </g>
    <path
      d='M3 3l26 26'
      strokeWidth='2'
      stroke={color || '#015295'}
    />
  </svg>
);

const eyeShow = (color: any, width: any, height: any) => (
  <svg
    viewBox='0 0 32 32'
    width={width || 32}
    height={height || 32}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      fill='#fff'
      strokeWidth='2'
      stroke={color || '#015295'}
    >
      <path d='M16 7C9.934 7 4.798 10.776 3 16c1.798 5.224 6.934 9 13 9s11.202-3.776 13-9c-1.798-5.224-6.934-9-13-9z' />
      <circle r='5' cx='16' cy='16' />
    </g>
  </svg>
);

export {
  eye,
  eye2,
  excel,
  eyeHide,
  eyeShow,
};
