/* eslint-disable max-len */
import React from 'react';
//////////////////////////////////////////////////

const parcel = (color: any, width: any, height: any) => (
  <svg
    viewBox='0 0 24 24'
    width={width || 24}
    height={height || 24}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#b68f2d'}
      d='M16.12 1.929L5.229 7.504.9 5.375 11.599.091c.24-.122.528-.122.78 0l3.741 1.837zm6.968 3.445l-11.082 5.49-4.15-2.045-.6-.305 10.903-5.575.6.304 4.329 2.131zm-11.97 7.073L11.106 24 .492 18.461c-.3-.158-.492-.475-.492-.816V6.957l4.498 2.216v3.896c0 .499.408.913.9.913s.9-.414.9-.913v-2.995l.6.292 4.22 2.081zm12.87-5.478l-11.07 5.466-.012 11.553L24 18.195l-.012-11.226z'
    />
  </svg>
);

const pauseInRound = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 15 15'
    width={width || 15}
    height={height || 15}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#930000'}
      d='M7.487 0A7.5 7.5 0 0 0 0 7.487a7.5 7.5 0 0 0 7.487 7.487 7.5 7.5 0 0 0 7.487-7.487A7.5 7.5 0 0 0 7.487 0zm0 14.02A6.54 6.54 0 0 1 .953 7.488 6.54 6.54 0 0 1 7.487.954a6.54 6.54 0 0 1 6.534 6.533 6.541 6.541 0 0 1-6.534 6.534zm1.401-9.682a.477.477 0 0 0-.477.477v5.344a.477.477 0 1 0 .953 0V4.815a.477.477 0 0 0-.476-.477zm-2.802 0a.477.477 0 0 0-.476.477v5.344a.477.477 0 1 0 .953 0V4.815a.477.477 0 0 0-.477-.477z'
    />
  </svg>
);

const payment = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 20 20'
    width={width || 20}
    height={height || 20}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#353535'}
      d='M5.7 8.426c-.194.155-.419.215-.738.244V7.024c.382.12.639.244.801.364.126.12.225.304.225.519-.031.215-.126.394-.288.519zM4.324 4.121c-.288.03-.513.12-.675.244-.162.155-.256.334-.256.519 0 .215.063.364.194.489.126.12.382.244.738.364V4.121zm15.054 3.482V17.74c0 1.252-1.057 2.26-2.371 2.26H2.371C1.057 20 0 18.992 0 17.74V2.26C0 1.008 1.057 0 2.371 0h9.16c.926 0 1.377.244 1.952.823l4.999 4.824c.67.609.895 1.008.895 1.956zM8.841 5.343h3.523c.419 0 .738-.304.738-.673v-.519c0-.364-.319-.673-.738-.673H8.841v1.866zM4.324 6.87V8.67c-.576-.09-1.12-.334-1.665-.793l-.707.793c.707.579 1.508.918 2.371 1.008v.668h.607v-.673c.675-.03 1.183-.215 1.602-.549a1.72 1.72 0 0 0 .607-1.312c0-.549-.162-.948-.513-1.222s-.895-.519-1.633-.673h-.031V4.176c.513.06.963.274 1.377.549l.639-.853c-.639-.399-1.314-.644-2.021-.673v-.519H4.35v.459c-.607.03-1.12.215-1.508.549a1.72 1.72 0 0 0-.607 1.312 1.62 1.62 0 0 0 .513 1.222c.325.249.838.494 1.576.649zm13.165 9.893c0-.364-.319-.673-.738-.673H2.56c-.419 0-.707.304-.707.673v.519c0 .364.319.673.707.673H16.75c.382 0 .738-.304.738-.673v-.519zm0-4.216c0-.364-.319-.673-.738-.673H2.56c-.419 0-.707.304-.707.673v.519c0 .364.319.673.707.673H16.75c.382 0 .738-.304.738-.673v-.519zm0-4.216c0-.364-.319-.673-.738-.673H8.841v1.861h7.915c.382 0 .738-.304.738-.673v-.514h-.005z'
    />
  </svg>
);

const pdf = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 16 20'
    width={width || 16}
    height={height || 20}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path fill='#e9e9e0' d='M11.267 0H.714C.42 0 .182.234.182.688v18.955c0 .123.238.357.532.357h14.572c.294 0 .532-.234.532-.357V4.635c0-.249-.034-.329-.093-.388L11.493.092C11.433.033 11.352 0 11.267 0z' />
    <path fill='#d9d7ca' d='M11.455.054v4.232h4.308z' />
    <path fill='#cc4b4c' d='M4.914 11.901a.59.59 0 0 1-.351-.116c-.379-.279-.43-.59-.406-.8.066-.582.798-1.19 2.176-1.81.547-1.178 1.068-2.628 1.378-3.84-.363-.776-.716-1.782-.459-2.373.09-.206.203-.365.413-.433.083-.028.292-.062.37-.062.182 0 .344.232.458.375.107.134.35.419-.136 2.43.49.993 1.185 2.006 1.85 2.7.477-.085.887-.128 1.221-.128.57 0 .915.13 1.056.399.116.222.068.482-.142.771a.97.97 0 0 1-.807.426c-.443 0-.958-.275-1.532-.816a17.7 17.7 0 0 0-3.21 1.007c-.304.634-.595 1.144-.866 1.519-.373.512-.695.751-1.013.751zm.968-1.83c-.777.429-1.094.781-1.117.98-.003.033-.013.119.157.247.054-.017.37-.159.96-1.227zm4.96-1.587c.295.224.368.337.562.337.085 0 .327-.003.44-.157.054-.075.075-.123.083-.148-.044-.024-.104-.07-.427-.07a5.46 5.46 0 0 0-.659.038zm-2.717-2.35a25.23 25.23 0 0 1-.973 2.701 18.22 18.22 0 0 1 2.363-.721c-.491-.56-.982-1.26-1.39-1.98zM7.904 3.11c-.036.012-.484.628.035 1.149.345-.755-.02-1.154-.035-1.149zM15.286 20H.714c-.294 0-.532-.234-.532-.523V13.93h15.636v5.548c0 .29-.238.523-.532.523z' />
    <path fill='#fff' d='M4.14 18.929h-.596V15.33h1.053a1.51 1.51 0 0 1 .463.073c.152.05.289.122.41.22a1.15 1.15 0 0 1 .293.354.96.96 0 0 1 .112.466c0 .183-.031.348-.094.496a1.03 1.03 0 0 1-.264.373c-.113.101-.249.18-.408.235s-.335.083-.527.083H4.14v1.299zm0-3.155V17.2h.547c.073 0 .145-.012.217-.037s.136-.064.196-.12a.57.57 0 0 0 .144-.231c.036-.1.054-.222.054-.369a1 1 0 0 0-.024-.202.57.57 0 0 0-.102-.22c-.052-.07-.124-.128-.216-.176a.82.82 0 0 0-.368-.07H4.14zm5.394 1.256c0 .295-.032.549-.097.759a1.87 1.87 0 0 1-.246.527 1.27 1.27 0 0 1-.335.335 1.75 1.75 0 0 1-.36.183 1.55 1.55 0 0 1-.319.078l-.214.017H6.576V15.33H7.68c.308 0 .58.048.813.144s.427.224.581.383a1.56 1.56 0 0 1 .346.544c.076.204.114.413.114.628zm-1.77 1.469c.405 0 .697-.127.876-.38s.268-.623.268-1.104c0-.15-.018-.298-.055-.445a.95.95 0 0 0-.21-.397 1.11 1.11 0 0 0-.426-.289c-.179-.073-.41-.11-.696-.11h-.348V18.5h.592zm3.242-2.725v1.133h1.531v.401h-1.531v1.621H10.4V15.33h2.291v.444z' />
  </svg>
);

const pencil = (color: string, width: any, height: any, fillOpacity: any) => (
  <svg
    width={width || 15}
    height={height || 15}
    viewBox='0 0 529 529'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#015295'}
      fillOpacity={fillOpacity || 1}
      d='M328.883 89.125l107.59 107.589-272.34 272.34L56.604 361.465l272.279-272.34zm189.23-25.948l-47.981-47.981c-18.543-18.543-48.653-18.543-67.259 0l-45.961 45.961 107.59 107.59 53.611-53.611c14.382-14.383 14.382-37.577 0-51.959zM.3 512.69c-1.958 8.812 5.998 16.708 14.811 14.565l119.891-29.069L27.473 390.597.3 512.69z'
    />
  </svg>
);

const phone = (color: string, w: any, h: any) => (
  <svg
    width={w || 16}
    height={h || 16}
    viewBox='0 0 16 16'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#2073a1'}
      d='M15.641 12.655l-2.47-2.477c-.493-.49-1.308-.476-1.817.035l-1.245 1.247-.246-.137c-.786-.436-1.862-1.035-2.994-2.17s-1.733-2.218-2.17-3.007l-.134-.24.836-.837.41-.412c.51-.511.524-1.328.033-1.82L3.374.358c-.491-.492-1.307-.477-1.817.035l-.696.702.019.019a4.039 4.039 0 0 0-.574 1.014C.172 2.483.089 2.82.05 3.16c-.326 2.71.91 5.188 4.265 8.55 4.637 4.647 8.373 4.296 8.535 4.279a4.167 4.167 0 0 0 1.03-.26c.366-.143.71-.339 1.008-.572l.015.013.705-.692c.51-.511.524-1.328.033-1.822z'
    />
  </svg>
);

const phone2 = (color: any, width: any, height: any) => (
  <svg
    viewBox='0 0 14 14'
    width={width || 14}
    height={height || 14}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#7d828c'}
      d='M9.366 12.764A11.69 11.69 0 0 1 4.28 9.772a11.69 11.69 0 0 1-2.992-5.085l-.009-.033c-.086-.303-.153-.538-.154-.859-.001-.368.119-.829.299-1.15.31-.552.975-1.259 1.55-1.549a1.75 1.75 0 0 1 1.58 0c.491.248 1.038.805 1.343 1.274a1.75 1.75 0 0 1 0 1.908c-.101.155-.245.299-.412.466-.052.052-.109.09-.071.169.378.787.893 1.525 1.547 2.179S8.354 8.26 9.141 8.638c.082.039.114-.017.169-.071.167-.167.311-.311.466-.412a1.75 1.75 0 0 1 1.908 0c.457.297 1.027.856 1.274 1.344a1.75 1.75 0 0 1 0 1.58c-.291.575-.998 1.24-1.549 1.55a2.55 2.55 0 0 1-1.15.299c-.322-.001-.557-.068-.859-.154l-.033-.009z'
    />
  </svg>
);

const pickup = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 20 20'
    width={width || 20}
    height={height || 20}
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      r='9.677'
      cx='9.677'
      cy='9.677'
      fill={color || '#353535'}
    />
    <path
      fill='#f3f3f3'
      d='M13.105 8.843l-2.834-3.61C10.155 5.078 10 5 9.805 5s-.349.078-.466.194l-3.183 3.61c-.233.272-.194.66.039.854.272.233.66.194.854-.039l2.096-2.368v5.551c0 .349.272.621.621.621s.621-.272.621-.621V7.368l1.786 2.213c.116.155.311.233.466.233a.75.75 0 0 0 .388-.116c.272-.233.311-.621.078-.854z'
    />
    <circle
      r='3.5'
      cx='15.5'
      cy='15.5'
      fill='#fff'
    />
    <path
      fill='#353535'
      d='M18.46 12.28c-.826-.825-1.923-1.28-3.091-1.28a4.34 4.34 0 0 0-3.091 1.28c-1.704 1.704-1.704 4.476 0 6.179.826.825 1.923 1.28 3.091 1.28a4.34 4.34 0 0 0 3.091-1.28c1.704-1.704 1.704-4.476 0-6.179zm-.562 5.617c-.676.675-1.574 1.047-2.529 1.047s-1.854-.372-2.529-1.047a3.58 3.58 0 0 1 0-5.056c.676-.675 1.574-1.047 2.529-1.047s1.854.372 2.529 1.047a3.58 3.58 0 0 1 0 5.056zm.096-2.592h-1.921v-1.921c0-.212-.172-.384-.384-.384s-.384.172-.384.384v1.921h-1.921c-.212 0-.384.172-.384.384s.172.384.384.384h1.921v1.921c0 .212.172.384.384.384s.384-.172.384-.384v-1.921h1.921c.212 0 .384-.172.384-.384s-.172-.384-.384-.384z'
    />
  </svg>
);

const pickupStopIcon = (color: string, width: any, height: any, bgColor: string) => (
  <svg
    viewBox='0 0 15 15'
    width={width || 15}
    height={height || 15}
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      r='7.5'
      cx='7.5'
      cy='7.5'
      fill={bgColor || '#2073a1'}
    />
    <path
      fill={color || '#fff'}
      d='M10.006 7.478L7.81 4.681a.429.429 0 0 0-.36-.181.5.5 0 0 0-.362.15L4.621 7.448c-.18.21-.15.512.03.662.211.18.512.15.662-.03l1.625-1.835v4.302c0 .27.21.481.481.481s.482-.21.482-.48V6.334L9.284 8.05a.47.47 0 0 0 .361.18c.09 0 .211-.03.301-.09.21-.18.241-.481.06-.662z'
    />
  </svg>
);

const pigBank = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 16 16'
    width={width || 16}
    height={height || 16}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#4c4c4c'}
      d='M7.868 3.5c.965 0 1.75-.785 1.75-1.75S8.833 0 7.868 0s-1.75.785-1.75 1.75.785 1.75 1.75 1.75zm7.517 3.4c-.343 0-.621.26-.621.579 0 .566-.325 1.063-.81 1.338-.544-2.583-2.994-4.538-5.927-4.538a6.37 6.37 0 0 0-2.852.667L4.3 4.132c-.138-.129-.358-.167-.54-.098a.46.46 0 0 0-.31.416v1.76a5.26 5.26 0 0 0-.882 1.31H1.039C.491 7.52 0 7.914 0 8.424v2.388c0 .51.491.95 1.04.95h1.278c.256.642.65 1.294 1.132 1.833v1.932c0 .249.242.474.509.474H5.26c.267 0 .467-.225.467-.474v-.42c.69.27 1.47.419 2.278.419s1.586-.149 2.276-.418v.42c0 .248.245.473.512.473h1.301a.47.47 0 0 0 .464-.474v-1.93c.897-.956 1.462-2.196 1.498-3.554C15.186 9.665 16 8.659 16 7.479c0-.32-.272-.578-.615-.578zM4.849 9.169c-.495 0-.898-.375-.898-.836s.403-.836.898-.836.898.375.898.836-.403.836-.898.836zm5.106-3.15c-.072.203-.274.331-.49.331-.055 0-.11-.008-.164-.025a4.37 4.37 0 0 0-2.547 0c-.272.084-.565-.053-.655-.305s.058-.526.329-.61a5.46 5.46 0 0 1 3.199 0c.271.084.418.357.328.61z'
    />
  </svg>
);

const pin = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 15 15'
    width={width || 15}
    height={height || 15}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#707070'}
      d='M14.99 5.916a.28.28 0 0 0-.173-.202 6.708 6.708 0 0 0-2.454-.46 7.214 7.214 0 0 0-2.742.54L5.41 2.278A3.067 3.067 0 0 0 4.828.103a.286.286 0 0 0-.42-.022L.09 4.318a.273.273 0 0 0 .024.415c.617.48 1.37.581 1.893.581l.293-.01 3.6 4.153c-.245.62-.996 2.853-.087 5.094a.28.28 0 0 0 .263.175.286.286 0 0 0 .2-.082l4.124-4.047 3.84 3.77a.285.285 0 0 0 .4 0 .274.274 0 0 0 0-.393l-3.84-3.77 4.112-4.035a.275.275 0 0 0 .077-.253z'
    />
  </svg>
);

const pinNew = (color: string, width: any, height: any) => (
  <svg
    width={width || 12}
    viewBox='0 0 12 16'
    height={height || 16}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#c8c8c8'}
      d='M10.305 8.316c-.502-.63-1.07-.945-1.703-.945V2.457a1.18 1.18 0 0 0 .863-.365 1.18 1.18 0 0 0 .365-.864c0-.332-.121-.62-.365-.863A1.18 1.18 0 0 0 8.602 0H2.46c-.333 0-.62.122-.864.365a1.18 1.18 0 0 0-.365.863 1.18 1.18 0 0 0 .365.864 1.18 1.18 0 0 0 .864.365V7.37c-.634 0-1.201.316-1.704.946a3.323 3.323 0 0 0-.753 2.126c0 .166.06.31.182.431a.59.59 0 0 0 .432.183h3.877l.73 4.654c.032.173.134.26.307.26h.01a.269.269 0 0 0 .196-.082.354.354 0 0 0 .1-.197l.49-4.635h4.117a.59.59 0 0 0 .432-.183.59.59 0 0 0 .183-.431c0-.787-.252-1.496-.754-2.126zM4.61 7.064c0 .089-.028.163-.086.22a.299.299 0 0 1-.22.087.299.299 0 0 1-.308-.307v-4.3c0-.09.029-.163.087-.22a.3.3 0 0 1 .22-.087.3.3 0 0 1 .221.086.299.299 0 0 1 .086.221v4.3z'
    />
  </svg>
);

const plain = (color: any, width: any, height: any) => (
  <svg
    viewBox='0 0 24 24'
    width={width || 24}
    height={height || 24}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#578dd1'}
      d='M.03 17.329L1.968 12 .03 6.671a.5.5 0 0 1 .06-.458C.184 6.08.337 6 .5 6h1.446c.52 0 .996.264 1.272.705L5.277 10h6.484L8.035.686A.5.5 0 0 1 8.5 0h.958a2.5 2.5 0 0 1 2.048 1.066L17.76 10H22c1.103 0 2 .897 2 2s-.897 2-2 2h-4.24l-6.253 8.934C11.039 23.602 10.273 24 9.458 24H8.5a.5.5 0 0 1-.464-.686L11.762 14H5.278l-2.06 3.295c-.277.441-.752.705-1.272.705H.5c-.163 0-.316-.08-.41-.213a.5.5 0 0 1-.06-.458z'
    />
  </svg>
);

const planeIcon = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 22 20'
    width={width || 22}
    height={height || 20}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#910c00'}
      d='M21.396.08a.32.32 0 0 0-.348-.05L.186 9.77a.323.323 0 0 0-.006.583l5.904 2.912a.32.32 0 0 0 .334-.032l5.74-4.31-4.506 4.657a.324.324 0 0 0-.09.25l.449 5.872a.323.323 0 0 0 .32.298.32.32 0 0 0 .243-.112l3.134-3.649 3.874 1.86a.32.32 0 0 0 .445-.195L21.491.42a.324.324 0 0 0-.095-.34z'
    />
  </svg>
);

const play = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 15 15'
    width={width || 15}
    height={height || 15}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#930000'}
      d='M7.5 0a7.5 7.5 0 1 0 0 15 7.5 7.5 0 1 0 0-15zm2.592 7.898l-3.75 2.344a.47.47 0 0 1-.248.071c-.078 0-.156-.019-.227-.059a.47.47 0 0 1-.241-.41V5.156a.47.47 0 0 1 .241-.41c.149-.083.331-.078.476.012l3.75 2.344a.47.47 0 0 1 0 .795z'
    />
  </svg>
);

const playInRound = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 15 15'
    width={width || 15}
    height={height || 15}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#930000'}
      d='M7.487 0C3.359 0 0 3.359 0 7.487s3.359 7.487 7.487 7.487 7.487-3.359 7.487-7.487S11.615 0 7.487 0zm0 14.02A6.54 6.54 0 0 1 .953 7.487 6.54 6.54 0 0 1 7.487.953a6.54 6.54 0 0 1 6.534 6.534 6.541 6.541 0 0 1-6.534 6.533z'
    />
    <path
      fill='none'
      stroke={color || '#930000'}
      d='M6 5.004v5.572c0 .292 4.006-2.549 3.997-2.786C9.988 7.552 6 4.884 6 5.004z'
    />
  </svg>
);

const plusArrowDown = (color: string, width: any, height: any, bgColor: string) => (
  <svg
    viewBox='0 0 19 18'
    width={width || 19}
    height={height || 18}
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      r='8.1'
      cx='8.1'
      cy='9.9'
      fill={color || '#930000'}
    />
    <path
      fill='#f3f3f3'
      d='M10.895 10.423l-2.55 3.249c-.105.14-.245.21-.419.21a.58.58 0 0 1-.419-.175l-2.865-3.249c-.21-.245-.175-.594.035-.769.245-.21.594-.175.769.035l1.887 2.131V6.859A.55.55 0 0 1 7.89 6.3a.55.55 0 0 1 .559.559v4.891l1.607-1.991c.105-.14.279-.21.419-.21.105 0 .245.035.349.105.245.21.279.559.07.769z'
    />
    <circle
      r='3.6'
      cy='3.78'
      cx='14.58'
      fill={bgColor || '#fff'}
    />
    <path
      fill={color || '#930000'}
      d='M17.514 1.152A3.91 3.91 0 0 0 14.732 0a3.91 3.91 0 0 0-2.782 1.152c-1.534 1.533-1.534 4.028 0 5.561a3.91 3.91 0 0 0 2.782 1.152 3.91 3.91 0 0 0 2.782-1.152c1.534-1.533 1.534-4.028 0-5.561zm-.506 5.056a3.2 3.2 0 0 1-2.276.942 3.2 3.2 0 0 1-2.276-.942 3.22 3.22 0 0 1 0-4.55 3.2 3.2 0 0 1 2.276-.942 3.2 3.2 0 0 1 2.276.942 3.22 3.22 0 0 1 0 4.55zm-.184-2.604h-1.729V1.876c0-.191-.155-.346-.346-.346s-.346.155-.346.346v1.729h-1.729c-.191 0-.346.155-.346.346s.155.346.346.346h1.729v1.729c0 .191.155.346.346.346s.346-.155.346-.346v-1.73h1.729c.191 0 .346-.155.346-.346s-.155-.346-.346-.346z'
    />
  </svg>
);

const plusArrowUp = (color: string, width: any, height: any, bgColor: string) => (
  <svg
    viewBox='0 0 19 18'
    width={width || 19}
    height={height || 18}
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      r='8.1'
      cx='8.1'
      cy='9.9'
      fill={color || '#930000'}
    />
    <path
      fill='#f3f3f3'
      d='M10.895 9.759L8.344 6.51c-.105-.14-.245-.21-.419-.21a.58.58 0 0 0-.419.175L4.641 9.724c-.21.245-.175.594.035.769.245.21.594.175.769-.035l1.887-2.131v4.996a.55.55 0 0 0 .559.559.55.55 0 0 0 .559-.559V8.431l1.607 1.991c.105.14.279.21.419.21.105 0 .245-.035.349-.105.245-.21.279-.559.07-.769z'
    />
    <circle
      r='3.6'
      cy='3.78'
      cx='14.58'
      fill={bgColor || '#fff'}
    />
    <path
      fill={color || '#930000'}
      d='M17.514 1.152A3.91 3.91 0 0 0 14.732 0a3.91 3.91 0 0 0-2.782 1.152c-1.534 1.533-1.534 4.028 0 5.561a3.91 3.91 0 0 0 2.782 1.152 3.91 3.91 0 0 0 2.782-1.152c1.534-1.533 1.534-4.028 0-5.561zm-.506 5.056a3.2 3.2 0 0 1-2.276.942 3.2 3.2 0 0 1-2.276-.942 3.22 3.22 0 0 1 0-4.55 3.2 3.2 0 0 1 2.276-.942 3.2 3.2 0 0 1 2.276.942 3.22 3.22 0 0 1 0 4.55zm-.184-2.604h-1.729V1.876c0-.191-.155-.346-.346-.346s-.346.155-.346.346v1.729h-1.729c-.191 0-.346.155-.346.346s.155.346.346.346h1.729v1.729c0 .191.155.346.346.346s.346-.155.346-.346v-1.73h1.729c.191 0 .346-.155.346-.346s-.155-.346-.346-.346z'
    />
  </svg>
);

const plusInCircleFilled = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 24 24'
    width={width || 24}
    height={height || 24}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#fff'}
      d='M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zM10.8 7.2a1.2 1.2 0 0 1 2.4 0v3.6h3.6a1.2 1.2 0 0 1 0 2.4h-3.6v3.6a1.2 1.2 0 0 1-2.4 0v-3.6H7.2a1.2 1.2 0 0 1 0-2.4h3.6V7.2z'
    />
  </svg>
);

const plusRound = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 15 15'
    width={width || 15}
    height={height || 15}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#015295'}
      d='M12.803 2.197A7.45 7.45 0 0 0 7.5 0a7.45 7.45 0 0 0-5.303 2.197 7.51 7.51 0 0 0 0 10.606A7.45 7.45 0 0 0 7.5 15a7.45 7.45 0 0 0 5.303-2.197 7.51 7.51 0 0 0 0-10.606zm-.964 9.642A6.096 6.096 0 0 1 7.5 13.636a6.1 6.1 0 0 1-4.34-1.797 6.143 6.143 0 0 1 0-8.678A6.096 6.096 0 0 1 7.5 1.364c1.639 0 3.18.638 4.339 1.797a6.143 6.143 0 0 1 0 8.678zm-.249-5.021H8.183V3.41a.682.682 0 0 0-1.364 0v3.41H3.41a.682.682 0 0 0 0 1.363h3.41v3.409a.682.682 0 0 0 1.363 0V8.18h3.409a.682.682 0 0 0 0-1.363z'
    />
  </svg>
);

const prevShape = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 7 11'
    width={width || 7}
    height={height || 11}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      strokeWidth='.5'
      fill={color || '#338cbd'}
      stroke={color || '#338cbd'}
      d='M5.903 9.908a.31.31 0 0 0 0-.45L1.79 5.504l4.114-3.961a.31.31 0 0 0 0-.45.34.34 0 0 0-.467 0l-4.34 4.179A.305.305 0 0 0 1 5.497c0 .077.032.162.097.224l4.34 4.18a.332.332 0 0 0 .466.007z'
    />
  </svg>
);

const printer = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 15 15'
    width={width || 15}
    height={height || 15}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g fill={color || '#930000'}>
      <path d='M13.943 2.286h-2.8V.383c0-.212-.142-.383-.318-.383h-6.65c-.176 0-.318.172-.318.383v1.903h-2.8C.474 2.286 0 2.857 0 3.559v6.098c0 .702.474 1.273 1.057 1.273h2.8v3.688c0 .212.142.383.318.383h6.65c.176 0 .318-.172.318-.383v-3.688h2.8c.583 0 1.057-.571 1.057-1.273V3.559c0-.702-.474-1.273-1.057-1.273zm-2.8.766h.759v1.072h-.759V3.052zM4.493.766h6.014v3.358H4.493V.766zM3.098 3.052h.759v1.072h-.759V3.052zm7.409 11.181H4.493V9.189h6.014v5.045zm3.857-4.577h0c0 .279-.189.506-.42.506h-2.8v-.974h.58c.176 0 .318-.172.318-.383s-.142-.383-.318-.383h-.898-6.65-.898c-.176 0-.318.172-.318.383s.142.383.318.383h.58v.974h-2.8c-.232 0-.42-.227-.42-.506V3.559c0-.279.189-.506.42-.506h1.405v1.455c0 .212.142.383.318.383h9.44c.176 0 .318-.172.318-.383V3.052h1.405c.232 0 .42.227.42.506v6.098z' />
      <circle cy='5.7' cx='2.019' r='1' />
      <path d='M7.731 12H6.115c-.191 0-.346.134-.346.3s.155.3.346.3h1.616c.191 0 .346-.134.346-.3s-.155-.3-.346-.3zm1.209-1.8H6.06c-.16 0-.29.134-.29.3s.13.3.29.3h2.88c.16 0 .29-.134.29-.3s-.13-.3-.29-.3z' />
    </g>
  </svg>
);

export {
  pdf,
  pin,
  play,
  phone,
  plain,
  parcel,
  pencil,
  phone2,
  pickup,
  pinNew,
  payment,
  pigBank,
  printer,
  planeIcon,
  plusRound,
  prevShape,
  playInRound,
  plusArrowUp,
  pauseInRound,
  plusArrowDown,
  pickupStopIcon,
  plusInCircleFilled,
};
