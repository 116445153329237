/* eslint-disable max-len */
import React from 'react';
//////////////////////////////////////////////////

const zoomMinus = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 24 24'
    width={width || 24}
    height={height || 24}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill='#015295'
      d='M13 10H5V8h8v2zm8.172 14l-7.387-7.387C12.397 17.487 10.761 18 9 18A9 9 0 1 1 9 0a9 9 0 0 1 9 9 8.95 8.95 0 0 1-1.387 4.785L24 21.172 21.172 24zM9 16a7.01 7.01 0 0 0 7-7 7.01 7.01 0 0 0-7-7 7.01 7.01 0 0 0-7 7 7.01 7.01 0 0 0 7 7z'
    />
  </svg>
);

const zoomPlus = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 24 24'
    width={width || 24}
    height={height || 24}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill='#015295'
      d='M13 10h-3v3H8v-3H5V8h3V5h2v3h3v2zm8.172 14l-7.387-7.387C12.397 17.487 10.761 18 9 18A9 9 0 1 1 9 0a9 9 0 0 1 9 9 8.95 8.95 0 0 1-1.387 4.785L24 21.172 21.172 24zM9 16a7.01 7.01 0 0 0 7-7 7.01 7.01 0 0 0-7-7 7.01 7.01 0 0 0-7 7 7.01 7.01 0 0 0 7 7z'
    />
  </svg>
);

export {
  zoomPlus,
  zoomMinus,
};
