/* eslint-disable max-len */
import React from 'react';
//////////////////////////////////////////////////

const hideTreeIcon = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 14 19'
    width={width || 14}
    height={height || 19}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      fill='none'
      stroke={color || '#930000'}
    >
      <rect x='7.5' y='7.5' rx='.5' width='6.25' height='3.755' strokeWidth='.8' />
      <rect x='7.5' rx='.5' width='6.25' height='3.755' strokeWidth='.8' />
      <rect x='7.5' y='15' rx='.5' width='6.25' height='3.755' strokeWidth='.8' />
      <path strokeWidth='.8' d='M7.1 1.583H1.447a.6.6 0 0 0-.6.6v14.37a.6.6 0 0 0 .6.6H7.1V1.584z' />
      <path d='M7.333 9.333H.667' />
    </g>
  </svg>
);

const hierarchy = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 17 15'
    width={width || 17}
    height={height || 15}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#930000'}
      d='M14.722 10.575V7.222h-6.11V4.425a2.224 2.224 0 0 0 1.944-2.203A2.225 2.225 0 0 0 8.333 0a2.225 2.225 0 0 0-2.222 2.222c0 1.131.85 2.066 1.945 2.203v2.797H1.944v3.353A2.224 2.224 0 0 0 0 12.778C0 14.003.997 15 2.222 15a2.225 2.225 0 0 0 2.222-2.222c0-1.131-.85-2.066-1.944-2.203V7.778h5.556v2.797a2.224 2.224 0 0 0-1.945 2.203c0 1.225.997 2.222 2.222 2.222a2.225 2.225 0 0 0 2.223-2.222c0-1.131-.85-2.066-1.945-2.203V7.778h5.556v2.797a2.224 2.224 0 0 0-1.945 2.203c0 1.225.997 2.222 2.222 2.222a2.225 2.225 0 0 0 2.223-2.222c0-1.131-.85-2.066-1.945-2.203zM6.667 2.222c0-.919.747-1.666 1.666-1.666.92 0 1.667.747 1.667 1.666 0 .92-.748 1.667-1.667 1.667S6.667 3.14 6.667 2.222zM3.889 12.778c0 .919-.748 1.666-1.667 1.666S.556 13.697.556 12.778c0-.92.747-1.667 1.666-1.667.92 0 1.667.748 1.667 1.667zm6.111 0c0 .919-.748 1.666-1.667 1.666s-1.666-.747-1.666-1.666c0-.92.747-1.667 1.666-1.667.92 0 1.667.748 1.667 1.667zm4.444 1.666c-.919 0-1.666-.747-1.666-1.666 0-.92.747-1.667 1.666-1.667.92 0 1.667.748 1.667 1.667s-.747 1.666-1.667 1.666z'
    />
  </svg>
);

const hotLoad = (color: any, width: any, height: any) => (
  <svg
    viewBox='0 0 30 40'
    width={width || 11}
    height={height || 13}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#000'}
      d='M18.75 0c-8.524 4.912-7.5 18.75-7.5 18.75S7.5 17.5 7.5 11.875C3.026 14.469 0 19.456 0 25c0 8.284 6.716 15 15 15s15-6.716 15-15c0-12.187-11.25-14.687-11.25-25zm-2.432 34.832c-3.014.752-6.067-1.083-6.819-4.097s1.083-6.067 4.097-6.819c7.277-1.814 8.189-5.907 8.189-5.907s3.629 14.555-5.468 16.823z'
    />
  </svg>
);

export {
  hotLoad,
  hierarchy,
  hideTreeIcon,
};
