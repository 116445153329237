/* eslint-disable max-len */
import React from 'react';
//////////////////////////////////////////////////

const van = (color: any, width: any, height: any) => (
  <svg
    viewBox='0 0 24 24'
    width={width || 24}
    height={height || 24}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#c656a0'}
      d='M15.402 3H.777a.76.76 0 0 0-.77.77v9.237a.77.77 0 0 0 .77.77h14.625a.77.77 0 0 0 .77-.77V3.77c0-.431-.346-.77-.77-.77zM0 17.624a.77.77 0 0 0 .77.77h.831c.323-1.609 1.647-2.871 3.294-3.079H.777A2.16 2.16 0 0 1 0 15.176v2.448zm22.276.77h.954a.77.77 0 0 0 .77-.77v-6.743c0-1.209-2.948-4.033-4.564-4.033h-1.724v6.158c0 1.27-1.039 2.309-2.309 2.309H5.896c1.647.208 2.971 1.47 3.294 3.079h5.496a3.88 3.88 0 0 1 3.795-3.117c1.87 0 3.441 1.347 3.795 3.117zm-3.302-9.652c3.941-.192 3.779 3.879 3.779 3.879h-3.779v-3.88zm-.496 12.727c1.284 0 2.325-1.041 2.325-2.325s-1.041-2.325-2.325-2.325-2.325 1.041-2.325 2.325 1.041 2.325 2.325 2.325zm-13.085 0c1.284 0 2.325-1.041 2.325-2.325S6.677 16.82 5.393 16.82s-2.325 1.041-2.325 2.325 1.041 2.325 2.325 2.325z'
    />
  </svg>
);

const vendorInvoiceList = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 16 16'
    width={width || 16}
    height={height || 16}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#930000'}
      d='M10.359 6.062c.133-.34.204-.785.204-1.379C10.563 1.666 8.81 0 5.633 0S.705 1.666.705 4.683c0 .594.072 1.039.205 1.38-.556.382-.91.865-.91 1.459 0 .76.265 1.032.876 1.095.216 3.05 2.258 5.444 4.758 5.444s4.541-2.394 4.758-5.444c.61-.063.875-.336.875-1.094 0-.595-.354-1.078-.908-1.462zM5.634.703c2.803 0 4.225 1.34 4.225 3.98 0 .458-.04.782-.119 1.026-1.15-.537-2.754-.787-4.106-.787s-2.956.25-4.107.787c-.078-.244-.119-.568-.119-1.026 0-2.64 1.422-3.98 4.226-3.98zm0 12.656c-2.111 0-3.843-2.08-4.054-4.735l4.054-.233c1.842 0 3.158.185 4.053.233-.21 2.654-1.942 4.735-4.053 4.735zm4.892-5.484c-.016.01-.116.057-.469.057-.318 0-.725-.036-1.242-.08a34.377 34.377 0 0 0-3.181-.164 34.22 34.22 0 0 0-3.181.163l-1.243.081c-.346 0-.448-.045-.457-.045-.001 0-.049-.079-.049-.363 0-1.12 2.598-1.899 4.93-1.899s4.93.78 4.93 1.899c0 .261-.041.349-.038.351zM6.988 2.096H4.193a.699.699 0 0 0-.7.699v.699a.7.7 0 0 0 .7.699h2.795a.699.699 0 0 0 .698-.7v-.698a.699.699 0 0 0-.698-.699zm0 1.398H4.193v-.699h2.795v.699z'
    />
    <circle
      r='4.219'
      cx='11.219'
      cy='11.219'
      fill='none'
      strokeWidth='.5'
      stroke={color || '#930000'}
    />
    <path
      fill={color || '#930000'}
      d='M11.5 9.125a.75.75 0 0 1 .75.75.375.375 0 1 0 .75 0 1.5 1.5 0 0 0-1.125-1.447v-.053a.375.375 0 1 0-.75 0v.053A1.498 1.498 0 0 0 10 9.875a1.5 1.5 0 0 0 1.5 1.5.75.75 0 1 1-.75.75.375.375 0 1 0-.75 0 1.5 1.5 0 0 0 1.125 1.447v.053a.375.375 0 1 0 .75 0v-.053A1.498 1.498 0 0 0 13 12.125a1.5 1.5 0 0 0-1.5-1.5.75.75 0 0 1 0-1.5z'
    />
  </svg>
);

const vin = (color: any, width: any, height: any) => (
  <svg
    viewBox='0 0 40 40'
    width={width || 40}
    height={height || 40}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      fill={color || '#fff'}
      d='M0 8.579A1.58 1.58 0 0 1 1.579 7h36.842A1.58 1.58 0 0 1 40 8.579c0 .09-.008.178-.022.263.014.086.022.173.022.263v21.842a1.58 1.58 0 0 1-1.417 1.571c-.053.005-.107.008-.161.008H1.579c-.054 0-.108-.003-.161-.008A1.58 1.58 0 0 1 0 30.947V9.105a1.59 1.59 0 0 1 .022-.263A1.59 1.59 0 0 1 0 8.579zm3.158 1.579h33.684v19.211H3.158V10.158zm13.577 2.855a1.32 1.32 0 0 1 .737 1.71l-4.278 10.774c-.215.541-.749.86-1.3.828-.594.093-1.193-.232-1.424-.814L6.191 14.736a1.32 1.32 0 0 1 .737-1.71c.675-.269 1.44.061 1.708.736l3.192 8.039 3.198-8.053c.268-.675 1.033-1.005 1.708-.736zm3.265.04c.727 0 1.316.589 1.316 1.316v10.79c0 .727-.589 1.316-1.316 1.316s-1.316-.589-1.316-1.316v-10.79c0-.727.589-1.316 1.316-1.316zm4.986.104a1.31 1.31 0 0 0-.512-.104c-.727 0-1.316.589-1.316 1.316v10.79c0 .727.589 1.316 1.316 1.316s1.316-.589 1.316-1.316v-7.077l6.008 7.738a1.31 1.31 0 0 0 .658.453 1.31 1.31 0 0 0 .702.203c.727 0 1.316-.589 1.316-1.316v-10.79c0-.727-.589-1.316-1.316-1.316s-1.316.589-1.316 1.316v7.217L25.65 13.61a1.31 1.31 0 0 0-.663-.454z'
    />
  </svg>
);

export {
  van,
  vin,
  vendorInvoiceList,
};
