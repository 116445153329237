/* eslint-disable max-len */
import React from 'react';
//////////////////////////////////////////////////

const warningIcon = (color: string, width: any, height: any, bgColor: string) => (
  <svg
    viewBox='0 0 20 20'
    width={width || 20}
    height={height || 20}
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='10'
      cy='10'
      r='9.5'
      fill={bgColor || '#ce2828'}
      stroke={color || '#353535'}
    />
    <path
      fill={color || '#353535'}
      d='M10 12.941c.547 0 1.002-.347 1.048-.798l.71-7.03c.042-.408-.125-.81-.46-1.112s-.804-.472-1.298-.472-.965.172-1.299.472-.5.704-.46 1.112l.711 7.03c.046.451.501.798 1.048.798zm0 1.177a1.176 1.176 0 1 0 0 2.352 1.176 1.176 0 0 0 0-2.352z'
    />
  </svg>
);

const word = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 22 20'
    width={width || 22}
    height={height || 20}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path fill='#eceff1' d='M21.313 17.303h-9.625c-.38 0-.688-.296-.688-.664V3.361c0-.366.308-.664.688-.664h9.624c.38 0 .688.298.688.664V16.64c0 .368-.308.664-.688.664z' />
    <path fill='#1976d2' d='M18.563 6.681h-6.875c-.381 0-.688-.296-.688-.664s.308-.664.688-.664h6.875c.379 0 .687.298.687.664s-.308.664-.688.664zm0 2.656h-6.875c-.381 0-.688-.297-.688-.664s.308-.664.688-.664h6.875c.379 0 .687.297.687.664s-.308.664-.688.664zm0 2.656h-6.875c-.381 0-.688-.298-.688-.666s.308-.664.688-.664h6.875c.379 0 .687.298.687.664s-.308.664-.688.664zm0 2.655h-6.875c-.381 0-.688-.296-.688-.665s.308-.664.688-.664h6.875c.379 0 .687.297.687.664s-.308.664-.688.664z' />
    <path fill='#1565c0' d='M12.126.196c-.157-.128-.367-.18-.565-.142l-11 1.991C.236 2.104 0 2.38 0 2.697v14.606a.67.67 0 0 0 .561.652l11 1.992c.043.008.084.012.127.012.159 0 .314-.053.438-.153.158-.126.249-.313.249-.511V.705a.65.65 0 0 0-.249-.51z' />
    <path fill='#fafafa' d='M8.933 13.394c-.035.304-.283.547-.598.584-.029.004-.056.006-.085.006a.69.69 0 0 1-.644-.43L6.188 9.898l-1.42 3.654c-.11.285-.398.458-.717.427-.312-.032-.563-.266-.607-.567l-.687-4.648c-.054-.36.208-.698.583-.75s.724.2.778.563l.307 2.077 1.118-2.88c.2-.518 1.086-.518 1.288 0l1.053 2.711.37-3.216c.043-.364.392-.625.76-.587.377.043.648.37.607.735l-.688 5.976z' />

  </svg>
);

const wordWide = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 14 16'
    width={width || 14}
    height={height || 16}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#930000'}
      d='M6.688 2.672a.25.25 0 0 0-.251.25v2.69H5.084a.25.25 0 0 0 0 .5h1.353v2.974H3.2l.076-1.055a.25.25 0 0 0-.497-.062 11.1 11.1 0 0 0-.08 1.117H.529a6.13 6.13 0 0 1 1.023-3.158.251.251 0 0 0-.418-.277 6.634 6.634 0 0 0-1.11 3.685A6.671 6.671 0 0 0 6.687 16a6.671 6.671 0 0 0 6.664-6.664 6.671 6.671 0 0 0-6.664-6.664zM.53 9.587H2.7c.023 1.055.191 2.069.489 2.973H1.437A6.122 6.122 0 0 1 .53 9.587zm1.25 3.474h1.59a7.25 7.25 0 0 0 .456.936c.28.482.598.882.942 1.195a6.187 6.187 0 0 1-2.987-2.131zm4.657 2.422c-.814-.104-1.58-.712-2.179-1.738a6.533 6.533 0 0 1-.345-.684h2.524v2.422zm0-2.924h-2.72c-.315-.89-.493-1.907-.517-2.972h3.237v2.973zm6.408-3.473h-2.169a10.29 10.29 0 0 0-.489-2.973h1.751c.537.871.863 1.886.907 2.973zm-1.25-3.474h-1.59a7.16 7.16 0 0 0-.456-.937 5.041 5.041 0 0 0-.942-1.195 6.187 6.187 0 0 1 2.987 2.132zM6.937 3.189c.814.104 1.58.712 2.179 1.738.125.216.24.445.345.685H6.938V3.189zm0 2.924h2.72c.315.891.493 1.907.517 2.973H6.938V6.113zm5 6.446h-.44a.251.251 0 0 0 0 .502h.096a6.186 6.186 0 0 1-2.992 2.133c.275-.25.534-.553.769-.91a.25.25 0 1 0-.418-.276c-.57.864-1.278 1.379-2.015 1.474v-2.421H8.29a.251.251 0 0 0 0-.502H6.938V9.588h3.237a10.397 10.397 0 0 1-.155 1.576.251.251 0 1 0 .494.087c.095-.539.15-1.097.162-1.663h2.169a6.121 6.121 0 0 1-.907 2.973zM3.214 0A2.659 2.659 0 0 0 .558 2.656c0 .995.646 1.79 1.271 2.558.457.562.93 1.143 1.147 1.796a.25.25 0 0 0 .475 0c.218-.653.69-1.234 1.147-1.795.625-.769 1.271-1.564 1.271-2.56A2.659 2.659 0 0 0 3.214 0zm.995 4.898c-.36.443-.728.896-.995 1.403-.268-.507-.636-.96-.996-1.403-.596-.732-1.159-1.425-1.159-2.242A2.16 2.16 0 0 1 3.214.5a2.16 2.16 0 0 1 2.154 2.155c0 .817-.563 1.51-1.159 2.242zM9.785 12a.786.786 0 1 0 .002 1.572A.786.786 0 0 0 9.785 12zm0 1.069a.284.284 0 1 1 0-.569.284.284 0 0 1 0 .569z'
    />
  </svg>
);

export {
  word,
  wordWide,
  warningIcon,
};
