/* eslint-disable max-len */
import React from 'react';
//////////////////////////////////////////////////

const lesnik = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 20 20'
    width={width || 20}
    height={height || 20}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#353535'}
      d='M0 0v1.438h1.444v.719H0v1.438h2.889v.719H0v1.438h1.444v.719H0v1.438h2.889v.719H0v1.438h1.444v.719H0v1.438h7.222V0zm7.778 12.778V20h1.438v-1.444h.719V20h1.438v-2.889h.719V20h1.438v-1.444h.719V20h1.438v-2.889h.719V20h1.438v-1.444h.719V20H20v-7.222zm-7.778 0h7.222V20H0z'
    />
  </svg>
);

const list1 = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 15 15'
    width={width || 15}
    height={height || 15}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#930000'}
      d='M0 .125v14.75h15V.125H0zm14.5 14.25H.5V.625h14v13.75zm-6.75-2.25h4.5a.25.25 0 1 0 0-.5h-4.5a.25.25 0 1 0 0 .5zm0-1.75h2.75a.25.25 0 1 0 0-.5H7.75a.25.25 0 1 0 0 .5zm0-1.75h4.5a.25.25 0 1 0 0-.5h-4.5a.25.25 0 1 0 0 .5zm0-1.75h4a.25.25 0 1 0 0-.5h-4a.25.25 0 1 0 0 .5zm0-1.75h3a.25.25 0 1 0 0-.5h-3a.25.25 0 1 0 0 .5zm0-1.75h4.5a.25.25 0 1 0 0-.5h-4.5a.25.25 0 1 0 0 .5zm-5.323 7.323a.25.25 0 1 0-.354.354l1.75 1.75a.248.248 0 0 0 .177.073.248.248 0 0 0 .177-.073l1.75-1.75a.25.25 0 1 0-.354-.354L4.25 12.021V2.979l1.323 1.324a.25.25 0 1 0 .354-.354l-1.75-1.75a.25.25 0 0 0-.354 0l-1.75 1.75a.25.25 0 1 0 .354.354L3.75 2.979v9.042l-1.323-1.323z'
    />
  </svg>
);

const loadSearch = (color: string, width: any, height: any) => (
  <svg
    width={width || 18}
    height={height || 18}
    viewBox='0 0 138 138'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g fill={color || '#930000'}>
      <path d='M106.375 0H8.625C3.862 0 0 3.85 0 8.6v68.8C0 82.15 3.862 86 8.625 86H46v-5.733H8.625A2.87 2.87 0 0 1 5.75 77.4V8.6a2.87 2.87 0 0 1 2.875-2.867h97.75A2.87 2.87 0 0 1 109.25 8.6v51.6H115V8.6c0-4.75-3.862-8.6-8.625-8.6z' />
      <path d='M34 84h6v17h-6zM20.4 69.4a2.8 2.8 0 0 1-2.8-2.8V61H12v5.6a8.4 8.4 0 0 0 8.4 8.4H26v-5.6h-5.6zM11 49h6v6h-6zm94.776 12.228A31.5 31.5 0 0 0 83.499 52c-17.397.001-31.5 14.104-31.499 31.501s14.104 31.5 31.501 31.499a31.5 31.5 0 0 0 22.271-9.224c12.303-12.3 12.304-32.245.004-44.548zm-22.292 47.976c-14.195-.005-25.698-11.516-25.693-25.71s11.516-25.698 25.71-25.693c6.822-.018 13.368 2.696 18.175 7.538 4.816 4.821 7.52 11.358 7.518 18.172-.005 14.195-11.516 25.698-25.71 25.693zm53.669 15.785l-18.151-18.142a2.89 2.89 0 0 0-4.089 0l-8.068 8.067a2.89 2.89 0 0 0 0 4.088l18.151 18.151a2.89 2.89 0 0 0 4.089-.009l8.068-8.067a2.89 2.89 0 0 0 0-4.088zm-10.112 6.031l-14.062-14.063 3.979-3.978 14.062 14.063-3.979 3.978z' />
      <path d='M101.007 105.071l4.066-4.066 10.026 10.026-4.066 4.066zM83 63v5.714c7.886.009 14.276 6.4 14.286 14.286H103c-.012-11.04-8.959-19.987-20-20zM29 98h23v6H29z' />
      <use href='#B' />
      <use href='#B' x='20' y='20' />
      <use href='#C' />
      <path d='M43.955 25.041l4.066-4.066L62.395 35.35l-4.066 4.066z' />
      <use href='#B' x='40' />
      <use href='#C' x='40.249' y='0.01' />
    </g>
    <defs>
      <path id='B' d='M43.5 12a8.5 8.5 0 1 0 0 17 8.5 8.5 0 1 0 0-17zm0 11.333c-1.565 0-2.833-1.269-2.833-2.833s1.269-2.833 2.833-2.833 2.833 1.269 2.833 2.833-1.269 2.833-2.833 2.833z' />
      <path id='C' d='M23.842 35.343l14.375-14.375 4.066 4.066-14.375 14.375z' />
    </defs>
  </svg>
);

const loadSearch2 = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 14 14'
    width={width || 14}
    height={height || 14}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#fff'}
      d='M5.02.943L1.042 3.166C.861 3.3.875 2.948.88 8.132l.066 4.793a.52.52 0 0 0 .407.191c.126-.014.014.036 2.193-.938l1.706-.749c.016 0 .889.383 1.939.848l1.999.848c.104 0 4.055-1.739 4.205-1.854.178-.134.167.232.167-5.146l-.038-4.985a.43.43 0 0 0-.479-.252c-.044.008-.921.484-1.95 1.055L9.174 2.972c-.03-.005-.891-.473-1.911-1.042C5.198.785 5.264.812 5.02.943zM7.164 2.68a4.91 4.91 0 0 1 .399.112c.864.282 1.578 1.036 1.909 2.026.284.842.15 1.788-.361 2.557l-.098.148 1.291 1.291 1.304 1.373.027.115c.005.016-.003.079-.019.137a.44.44 0 0 1-.53.306c-.09-.025-.306-.23-1.397-1.318l-1.292-1.29-.148.098c-.312.208-.719.375-1.127.465-.098.022-.347.036-.629.036-.509-.003-.744-.044-1.14-.205a2.97 2.97 0 0 1-1.846-2.587 3.13 3.13 0 0 1 2.628-3.281 3.91 3.91 0 0 1 1.028.016zm-.971.85c-.604.118-1.107.446-1.436.93a1.97 1.97 0 0 0-.366 1.228 2.08 2.08 0 0 0 .626 1.545c.421.421.93.629 1.545.629s1.124-.208 1.545-.629.629-.93.629-1.545a1.97 1.97 0 0 0-.342-1.184c-.306-.473-.741-.785-1.312-.941-.186-.049-.705-.068-.889-.033z'
    />
  </svg>
);

const location = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 16 15'
    width={width || 16}
    height={height || 15}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g fill={color || '#930000'}>
      <path d='M14.358 6.115H11.33a.278.278 0 1 0 0 .555h2.529l.805 7.5H.63l.945-7.5h2.533a.278.278 0 1 0 0-.555H1.085L0 14.725h15.283l-.925-8.61zm-6.954 4.432l-.124.07-.18.124a.278.278 0 0 0 .33.448 3.01 3.01 0 0 1 .434-.267.276.276 0 0 0 .128-.352l.007-.006c.502-.532 2.196-2.527 2.897-5.85.142-.673.174-1.32.095-1.922C10.75.966 9.617.002 7.709.002S4.668.966 4.43 2.793a5.716 5.716 0 0 0 .094 1.921c.695 3.291 2.361 5.278 2.88 5.833zM4.979 2.865C5.183 1.312 6.076.556 7.709.556s2.528.756 2.731 2.309a5.17 5.17 0 0 1-.088 1.735c-.63 2.987-2.133 4.883-2.642 5.457C7.2 9.482 5.697 7.586 5.067 4.6a5.17 5.17 0 0 1-.088-1.735z' />
      <path d='M7.71 5.257c1.125 0 2.04-.915 2.04-2.04s-.915-2.041-2.04-2.041a2.04 2.04 0 1 0 0 4.08zm0-3.525a1.49 1.49 0 0 1 1.485 1.485c0 .819-.666 1.485-1.485 1.485a1.487 1.487 0 0 1-1.485-1.485c0-.82.666-1.485 1.485-1.485zm1.02 8.315a.278.278 0 1 0 .116.544l.524-.087a.277.277 0 0 0 .241-.31.275.275 0 0 0-.31-.242l-.571.095zm1.436.113c.006.15.13.266.278.266h.012l.556-.024a.278.278 0 0 0 .264-.29.285.285 0 0 0-.29-.264l-.554.023a.277.277 0 0 0-.266.289zm-3.935 1.278l-.438.308a.278.278 0 0 0 .302.466l.471-.33a.278.278 0 0 0-.335-.444zm6.191-1.832a.99.99 0 0 1-.374.146.278.278 0 1 0 .113.545 1.54 1.54 0 0 0 .582-.237l.037-.027a.278.278 0 0 0-.335-.444l-.023.017zm-9.961 2.172a.277.277 0 1 0-.536-.145 1.858 1.858 0 0 0-.057.652.278.278 0 0 0 .553-.057 1.11 1.11 0 0 1-.003-.177c.004-.09.019-.182.043-.273zm9.955-4.331a1.634 1.634 0 0 0-.663.05.277.277 0 0 0 .076.546c.025 0 .05-.004.075-.011.145-.04.293-.053.438-.034a.278.278 0 1 0 .074-.55zm.553 1.376a.278.278 0 0 0 .53.164 1.184 1.184 0 0 0 .053-.337 1.1 1.1 0 0 0-.07-.386.278.278 0 0 0-.521.193c.024.064.036.13.036.193v.032a.626.626 0 0 1-.028.141zm-8.113 3.445a7.791 7.791 0 0 1-.493.21.278.278 0 1 0 .2.519l.529-.226a.278.278 0 1 0-.236-.503zm-2.022-1.157c.044 0 .09-.01.132-.034a.68.68 0 0 1 .253-.08.45.45 0 0 1 .113 0 .278.278 0 1 0 .073-.55 1.026 1.026 0 0 0-.248-.002c-.159.018-.313.066-.457.145a.277.277 0 0 0 .134.521zm-.058 2.179a1.774 1.774 0 0 0 .656.03.277.277 0 1 0-.07-.551 1.361 1.361 0 0 1-.337.004.926.926 0 0 1-.11-.021.277.277 0 1 0-.14.537z' />
    </g>
  </svg>
);

const locationMarker = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 8 10'
    width={width || 8}
    height={height || 10}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#353535'}
      d='M3.75 0A3.75 3.75 0 0 0 0 3.75C0 5.82 3.125 10 3.75 10S7.5 5.82 7.5 3.75A3.75 3.75 0 0 0 3.75 0zm0 6.25a2.503 2.503 0 0 1-2.5-2.5c0-1.378 1.121-2.5 2.5-2.5s2.5 1.122 2.5 2.5-1.121 2.5-2.5 2.5z'
    />
  </svg>
);

const locationPin = (color: any, width: any, height: any) => (
  <svg
    viewBox='0 0 14 14'
    width={width || 14}
    height={height || 14}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#7d828c'}
      d='M7 0C4.106 0 1.75 2.372 1.75 5.288c0 4.143 4.757 8.422 4.959 8.601.083.074.187.111.291.111a.44.44 0 0 0 .291-.11c.202-.18 4.959-4.458 4.959-8.602C12.25 2.372 9.895 0 7 0zm0 8.167A2.92 2.92 0 0 1 4.083 5.25 2.92 2.92 0 0 1 7 2.333 2.92 2.92 0 0 1 9.917 5.25 2.92 2.92 0 0 1 7 8.167z'
    />
  </svg>
);

const ltl = (color: any, width: any, height: any) => (
  <svg
    viewBox='0 0 24 24'
    width={width || 24}
    height={height || 24}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#b07968'}
      d='M23.25 24h-4a.75.75 0 0 1-.75-.75V22h-3v1.25a.75.75 0 0 1-.75.75h-5.5a.75.75 0 0 1-.75-.75V22h-3v1.25a.75.75 0 0 1-.75.75h-4a.75.75 0 0 1-.75-.75V20h24v3.25a.75.75 0 0 1-.75.75zM16 0h-3v2a1 1 0 1 1-2 0V0H8a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zm-6 10H7v2a1 1 0 1 1-2 0v-2H2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zm12 0h-3v2a1 1 0 1 1-2 0v-2h-3a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1z'
    />
  </svg>
);

export {
  ltl,
  list1,
  lesnik,
  location,
  loadSearch,
  loadSearch2,
  locationPin,
  locationMarker,
};
