
import * as R from 'ramda';
import { put, all, fork, race, take, call, select } from 'redux-saga/effects';
// common
import {
  setGoogleApiKey,
  globalCleanReports,
  initialDataLoadFail,
  initSwitchBranchLogic,
  initialDataLoadRequest,
  initialDataLoadSuccess,
  getDocumentTemplateSuccess,
  getUserMonitoringIntegrationUseListRequest } from '../common/actions';
// components
import { closeLoader } from '../components/loader/actions';
import { initTableOrderIDB } from '../common/idb/order/sagas';
import { initTableResizeIDB } from '../common/idb/resize/sagas';
// features
import { getMenusSaga } from '../features/menu/sagas';
import PC from '../features/permission/role-permission';
import { getLocaleSaga } from '../features/locale/sagas';
import { getReferenceScopesSaga } from '../features/reference/sagas';
import { getItemListRequest } from '../features/notification/actions';
import { getPermissionsListSaga } from '../features/permission/sagas';
import { makeSelectAuthorities } from '../features/permission/selectors';
import { getSequenceTransformersSaga } from '../features/sequence/sagas';
import { makeSelectCurrentUserSettingsFields } from '../features/profile/selectors';
import { initialBranchTreeSaga, initialBranchListSaga } from '../features/branch/sagas';
import { getAppReleaseSaga, getAppReleaseSagaByExternalUrl } from '../features/app-release/sagas';
import {
  getCurrentUserSettingsFail,
  getCurrentUserSettingsRequest,
  getCurrentUserSettingsSuccess } from '../features/profile/actions';
import {
  receivedLogInFail,
  receivedLogInSuccess,
  receivedNalLogInSuccess,
  receivedBoshLogInSuccess,
  receivedSessionValidationFail,
  receivedSessionValidationSuccess,
} from '../features/auth/actions';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
import { isNotLocalDevelopment } from '../helpers/env';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
import routesMap, { defaultLoginScreenOptions, customerDefaultLoginScreenOptions } from '../utilities/routes';
//////////////////////////////////////////////////

function* initialDataLoadingSaga() {
  try {
    yield put(initialDataLoadRequest());
    yield put(getCurrentUserSettingsRequest());

    const { successSettings } = yield race({
      successSettings: take(getCurrentUserSettingsSuccess),
      failUserSettings: take(getCurrentUserSettingsFail),
    });

    if (successSettings) {
      const results = yield all([
        // TODO: remove after testing
        // call(getLocaleSaga, successSettings),
        call(getPermissionsListSaga),
        call(getMenusSaga),
        call(initTableOrderIDB),
        call(initTableResizeIDB),
        call(getReferenceScopesSaga),
        call(initialBranchTreeSaga),
        call(initialBranchListSaga, { payload: R.path(['payload', GC.BRANCH_GUID], successSettings) }),
        call(getSequenceTransformersSaga),

        put(getItemListRequest(true)),
      ]);

      if (isNotLocalDevelopment) {
        yield call(getAppReleaseSaga);
        yield call(getAppReleaseSagaByExternalUrl);
      }

      if (R.includes(false, results)) {
        throw Error('initialDataLoadingSaga throw Error');
      }

      yield put(initialDataLoadSuccess());

      return true;
    }
    throw Error('initialDataLoadingSaga throw Error');
  } catch (error) {
    yield put({ type: GC.CLEAR_STORE });
    yield put(closeLoader());
    yield put(initialDataLoadFail());

    yield call(G.handleException, error, 'initialDataLoadingSaga');
    yield call(G.goToRoute, routesMap.loginPage);
  }
}

function* defaultRouteAndCleanReportsSaga({ branchGuid, shouldRedirect }: Object) {
  try {
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
        names: GC.UI_ROUTE_LIST_GENERAL_DEFAULT_LOAD,
      },
    };

    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.branchConfigsEndpoint,
      options,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const currentUser = yield select(makeSelectCurrentUserSettingsFields());
      const authorities = yield select(makeSelectAuthorities());

      const configStore = G.mapConfigValuesByName(data);

      const defaultLoadList = G.getConfigValueFromState(
        GC.UI_ROUTE_LIST_GENERAL_DEFAULT_LOAD,
        configStore.configs,
      );

      const defaultAfterLoginPage = G.getPropFromObject(GC.FIELD_DEFAULT_AFTER_LOGIN_PAGE, currentUser);

      let defaultRoute = R.compose(
        R.pathOr(routesMap.routeListLoad, [defaultAfterLoginPage, 'route']),
        R.indexBy(R.prop(GC.FIELD_VALUE)),
      )(defaultLoginScreenOptions);

      const list = G.ifElse(
        G.checkStringsContains([PC.TEL_READ, PC.TEL_WRITE], authorities),
        R.pathOr(R.or(defaultLoadList, GC.FIELD_CLO), ['defaultRouteType'], currentUser),
        GC.FIELD_CLO,
      );

      yield call(G.setItemToWindow, 'amousDefaultRouteTab', list);

      const isClo = R.or(
        R.equals(defaultAfterLoginPage, GC.ROUTE_PATH_DISPATCH_BOARD_CLO),
        R.and(R.isNil(defaultAfterLoginPage), R.equals(defaultLoadList, GC.FIELD_CLO)),
      );

      const isTel = R.or(
        R.equals(defaultAfterLoginPage, GC.ROUTE_PATH_DISPATCH_BOARD_TEL),
        R.and(R.isNil(defaultAfterLoginPage), R.equals(defaultLoadList, GC.FIELD_TEL)),
      );

      if (isClo) {
        if (G.checkStringsContains([PC.CLO_READ, PC.CLO_WRITE], authorities)) {
          defaultRoute = routesMap.routeListOrder;
        } else {
          defaultRoute = routesMap.routeListLoad;
        }
      }

      if (isTel) {
        if (G.checkStringsContains([PC.TEL_READ, PC.TEL_WRITE], authorities)) {
          defaultRoute = routesMap.routeListLoad;
        } else {
          defaultRoute = routesMap.routeListOrder;
        }
      }

      if (R.pathEq(GC.USER_ROLE_TYPE_CUSTOMER, [GC.FIELD_USER_TYPE], currentUser)) {
        defaultRoute = R.compose(
          R.pathOr(routesMap.routeListOrder, [defaultAfterLoginPage, 'route']),
          R.indexBy(R.prop(GC.FIELD_VALUE)),
        )(customerDefaultLoginScreenOptions);
      }

      if (G.isTrue(shouldRedirect)) {
        yield call(G.goToRoute, defaultRoute);

        yield put(globalCleanReports());
      }
    } else {
      yield call(G.handleFailResponse, res, 'defaultRouteAndCleanReportsSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'defaultRouteAndCleanReportsSaga exception');
  }
}

function* getGoogleApiKeySaga({ branchGuid }: Object) {
  try {
    setGoogleApiKey(null);

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.distanceCalculatorConfigGoogleApiKey,
      options,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(setGoogleApiKey(R.or(data, 'AIzaSyB9Vj08tH1omYsW5k8GDWAY5PtfrE_OxAk')));
    } else {
      yield call(G.handleFailResponse, res, 'getGoogleApiKeySaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getGoogleApiKeySaga exception');
  }
}

function* getDocumentTemplatesRequest({ branchGuid }: Object) {
  try {
    const options = {
      params: { [GC.FIELD_CURRENT_BRANCH]: branchGuid },
    };

    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.availableDocumentTemplates,
      options,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(getDocumentTemplateSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getDocumentTemplatesRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getDocumentTemplatesRequest exception');
  }
}

function* getAverageFuelPriceLatest() {
  try {
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.averageFuelPriceLatest,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const fuelPrice = R.path(['priceMap'], data);

      G.setItemToWindow(
        'amousFuelIndexInfo',
        { fuelPrice, fuelPricesInfo: data },
      );
    } else {
      yield call(G.handleFailResponse, res, 'getAverageFuelPriceLatest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getAverageFuelPriceLatest exception');
  }
}

export function* initialDataWatcherSaga() {
  while (true) { // eslint-disable-line
    const { logInSuccess, sessionSuccess, nalLogInSuccess, boshLogInSuccess } = yield race({
      nalLogInSuccess: take(receivedNalLogInSuccess),
      boshLogInSuccess: take(receivedBoshLogInSuccess),
      sessionSuccess: take(receivedSessionValidationSuccess),
      logInSuccess: take(receivedLogInSuccess),
      // TODO: remove after testing
      // sFail: take(receivedSessionValidationFail),
      // lFail: take(receivedLogInFail),
    });

    if (R.or(sessionSuccess, logInSuccess)) {
      const sessionBranchGuid = R.path(['payload', GC.FIELD_BRANCH_UNDERSCORE_GUID], sessionSuccess);
      const loginBranchGuid = R.path(['payload', GC.FIELD_BRANCH_UNDERSCORE_GUID], logInSuccess);
      const branchGuid = R.or(sessionBranchGuid, loginBranchGuid);
      const doRedirect = G.ifElse(logInSuccess, true, false);

      yield call(G.detectMobile);
      yield call(initialDataLoadingSaga);
      yield call(defaultRouteAndCleanReportsSaga, { branchGuid, shouldRedirect: doRedirect });
      yield call(getLocaleSaga);
      yield call(getGoogleApiKeySaga, { branchGuid });
      yield call(getDocumentTemplatesRequest, { branchGuid });
      yield call(getAverageFuelPriceLatest);

      yield put(getUserMonitoringIntegrationUseListRequest());
    }

    if (nalLogInSuccess) {
      const branchGuid = R.path(['payload', GC.FIELD_BRANCH_UNDERSCORE_GUID], nalLogInSuccess);

      yield call(defaultRouteAndCleanReportsSaga, { branchGuid, shouldRedirect: false });
      yield call(initialDataLoadingSaga);
      yield call(getLocaleSaga);
      yield call(getGoogleApiKeySaga, { branchGuid });
      yield call(getDocumentTemplatesRequest, { branchGuid });

      yield put(getUserMonitoringIntegrationUseListRequest());

      yield call(G.goToRoute, routesMap.customerPortal);
    }


    if (boshLogInSuccess) {
      const branchGuid = R.path(['payload', GC.FIELD_BRANCH_UNDERSCORE_GUID], boshLogInSuccess);

      yield call(defaultRouteAndCleanReportsSaga, { branchGuid, shouldRedirect: true });
      yield call(initialDataLoadingSaga);
      // yield call(getLocaleSaga);
      yield call(getGoogleApiKeySaga, { branchGuid });
      yield call(getDocumentTemplatesRequest, { branchGuid });

      yield put(getUserMonitoringIntegrationUseListRequest());
    }
  }
}

export function* watchSwitchBranchSuccess() {
  while (true) { // eslint-disable-line
    const action = yield take(initSwitchBranchLogic);

    const branchGuid = R.path(['payload', GC.FIELD_GUID], action);

    yield fork(getGoogleApiKeySaga, { branchGuid });
    yield fork(getDocumentTemplatesRequest, { branchGuid });
    yield fork(defaultRouteAndCleanReportsSaga, { branchGuid, shouldRedirect: true });

    yield put(getUserMonitoringIntegrationUseListRequest());
  }
}
