/* eslint-disable max-len */
import React from 'react';
//////////////////////////////////////////////////

const file = (color: any, width: any, height: any) => (
  <svg
    viewBox='0 0 14 16'
    width={width || 14}
    height={height || 16}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#4c4c4c'}
      d='M12.949 3.95L9.679.681C9.33.331 8.87.105 8.297 0v5.333h5.334c-.105-.573-.332-1.034-.681-1.383zM7.837 6.667a1 1 0 0 1-.724-.292.95.95 0 0 1-.298-.708V0H1.022C.738 0 .497.097.298.292A.95.95 0 0 0 0 1v14c0 .278.1.514.298.708s.44.292.724.292h11.586a.99.99 0 0 0 .723-.292.95.95 0 0 0 .299-.708V6.667H7.837z'
    />
  </svg>
);

const filter = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 18 20'
    width={width || 18}
    height={height || 20}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g fill='none'>
      <g
        strokeWidth='.2'
        fill={color || '#930000'}
        stroke={color || '#930000'}
      >
        <path d='M5.165 6.473v.277c-2.362.548-3.305 1.127-2.832 1.735.711.913 3.084 1.406 6.54 1.413s6.536-.73 6.992-1.678c.304-.632-1.431-1.214-5.206-1.747v-.167l4.115.533c.893.243 2.744.741 2.32 1.853s-4.052 2.027-8.221 1.97S1.386 9.67.903 8.526C.58 7.763 2 7.079 5.165 6.473z' />
        <path d='M2.015 9.467l5.637 5.993c.414.244.861.366 1.343.366s.969-.122 1.46-.366l6.425-6.419c.04.052-2.421 1.303-4.928 1.552-1.671.166-4.057.083-7.158-.249l4.201 5.482c-.538-.065-.986-.187-1.343-.366a2103.98 2103.98 0 0 1-5.637-5.993z' />
      </g>
      <path
        strokeWidth='.2'
        stroke={color || '#930000'}
        d='M5.13 7.634c-1.724.212-2.636.463-2.737.752s.357.594 1.373.915'
      />
      <path
        fill={color || '#930000'}
        d='M8.915 15.823v1.4c0 .016.472.049 1.057-.221.126-.058.285-.19.478-.398l-.075-1.069-.75.288h-.71z'
      />
      <path
        strokeWidth='.2'
        stroke={color || '#930000'}
        d='M7.615 15.513v1.072c.31.441.753.668 1.33.681a1.903 1.903 0 0 0 1.478-.624v-1.161c-.534.261-1.026.379-1.478.354a3.853 3.853 0 0 1-1.33-.322z'
      />
      <path
        fill={color || '#930000'}
        d='M9 0h4.5v4.5H9zM5 2h2.5v2.5H5zm1 4h2v2H6zM4 5h1v1H4zm5 3h1v1H9zm-1 9.5h1v1H8zM9 19h1v1H9zm-1 0h1v1H8zm1.5-1h1v1h-1zM8.3 5h1.2v1.2H8.3z'
      />
      <path
        strokeWidth='.2'
        stroke={color || '#930000'}
        d='M10.612 7.526c2.965.201 4.601.416 4.908.644s.023.54-.848.936'
      />
    </g>
  </svg>
);

const fleetsOnMap = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 50 50'
    width={width || 50}
    height={height || 50}
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='25'
      cy='25'
      r='22.5'
      strokeWidth='5'
      strokeOpacity='.3'
      fill={color || '#015295'}
      stroke={color || '#015295'}
    />
    <path
      fill='#fff'
      d='M10.699 17.453H27.74a2 2 0 0 1 2 2v8.41a2 2 0 0 1-2 2h-1.672H10.7a2 2 0 0 1-2-2v-8.41a2 2 0 0 1 2-2z'
    />
    <g fill={color || '#015295'}>
      <path
        d='M10.096 20.236h1.347v6.396h-1.347zm3.737 0h1.347v6.396h-1.347zm3.871 0h1.347v6.396h-1.347zm3.872.068h1.347V26.7h-1.347zm3.871 0h1.347V26.7h-1.347z'
      />
      <circle r='1.178' cx='23.427' cy='30.167' />
      <circle r='1.178' cx='19.051' cy='30.167' />
    </g>
    <path
      fill='#fff'
      d='M20.63 30.264c.186 1.606.979 2.41 2.38 2.41s2.197-.804 2.39-2.41h.214c.149 1.606.933 2.41 2.352 2.41s2.228-.804 2.426-2.41h4.7c.243 1.606 1.076 2.41 2.499 2.41s2.22-.804 2.39-2.41l1.455-.119v-3.103a1 1 0 0 0-.752-.969l-2.543-.652c.146-1.327-.076-2.224-.667-2.69s-1.664-.605-3.217-.42c-.061-1.058-.484-1.92-1.27-2.585s-1.65-.862-2.595-.589v8.334h-9.399c-.33 0-.96.218-1.094.837-.025.118-1.034.638-3.025 1.56h3.756v.396z'
    />
    <g fill={color || '#015295'}>
      <circle cx='23.007' cy='30.185' r='1' />
      <circle cx='28.017' cy='30.185' r='1' />
      <circle cx='37.576' cy='30.185' r='1' />
      <path d='M34.686 23.567c1.164-.053 1.842.019 2.034.216s.268.731.23 1.603h-2.264v-1.82z' />
    </g>
    <g fill='#fff'>
      <path d='M27.615 28.41c1.399-1.29 2.28-2 2.641-2.13.544-.197 1.006-.137.988 0-.013.091-.377.8-1.092 2.13h-2.537z' />
      <circle r='2.288' cx='13.557' cy='30.403' />
    </g>
    <circle
      r='1'
      cx='13.557'
      cy='30.403'
      fill={color || '#015295'}
    />
  </svg>
);

const folderClosed = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 13 11'
    width={width || 13}
    height={height || 11}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g stroke={color || '#353535'}>
      <path
        strokeWidth='0.5'
        fill={color || '#231f20'}
        d='M11.468 2.174H6.751L5.738 1.057A.17.17 0 0 0 5.613 1H1.532C1.238 1 1 1.263 1 1.587v7.826c0 .324.238.587.532.587h9.935c.294 0 .532-.263.532-.587V2.761c0-.324-.238-.587-.532-.587zm.177 7.239c0 .108-.079.196-.177.196H1.532c-.098 0-.177-.088-.177-.196V1.587c0-.108.079-.196.177-.196h4.007l1.013 1.117a.17.17 0 0 0 .125.057h4.79c.098 0 .177.088.177.196v6.652z'
      />
      <path d='M1.197 4.367h10.618' />
    </g>
  </svg>
);

const folderOpened = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 13 11'
    width={width || 13}
    height={height || 11}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      strokeWidth='0.5'
      fill={color || '#353535'}
      stroke={color || '#353535'}
      d='M11.54 3.939h-.64V2.788a.5.5 0 0 0-.501-.502H5.861L4.944 1H1.501A.5.5 0 0 0 1 1.502v8.024h.001c-.001.103.031.205.096.289.09.118.226.185.373.185h8.206c.212 0 .399-.143.449-.333L12 4.517v-.033c0-.316-.194-.546-.46-.546zM1.367 1.502c0-.074.06-.134.134-.134h3.255l.917 1.286h4.727c.074 0 .134.06.134.134v1.151H3.334a.46.46 0 0 0-.078.007c-.178.03-.327.16-.371.326L1.367 8.42V1.502zm8.409 8.054c-.012.045-.053.077-.1.077H1.47c-.045 0-.071-.026-.082-.041s-.029-.047-.023-.075l1.868-5.134c.012-.045.053-.077.1-.077H10.9h.64c.07 0 .087.1.092.15l-1.856 5.1z'
    />
  </svg>
);

const fuel = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 24 24'
    width={width || 20}
    height={height || 20}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#fff'}
      d='M14.5 4.5a.75.75 0 0 0-.75-.75h-7.5a.75.75 0 0 0-.75.75v6a.75.75 0 0 0 .75.75h7.5a.75.75 0 0 0 .75-.75v-6zM13 9.75H7v-4.5h6v4.5zm10.103-4.432l-2.991-1.5c-.371-.186-.817-.035-1.002.335a.75.75 0 0 0 .338 1.006l1.139.569c-.03.082-.07.168-.07.26 0 .976.733 1.801 1.483 2.112v9.138a.75.75 0 1 1-1.5 0v-6c0-1.811-1.5-3.326-3-3.674V2.989C17.5 1.335 16.19 0 14.536 0h-9C3.882 0 2.5 1.335 2.5 2.989v16.786l-1.085.543a.75.75 0 0 0-.415.671v2.25c0 .414.372.761.786.761h16.5c.414 0 .714-.347.714-.761v-2.25a.75.75 0 0 0-.415-.671l-1.085-.543V9.127c.75.311 1.5 1.135 1.5 2.112v6c0 1.241 1.009 2.25 2.25 2.25s2.25-1.009 2.25-2.25V5.989c0-.284-.142-.544-.397-.671zM17.5 22.5h-15v-1.047l1.085-.543A.75.75 0 0 0 4 20.239V2.989C4 2.162 4.709 1.5 5.536 1.5h9c.827 0 1.464.662 1.464 1.489v17.25a.75.75 0 0 0 .415.671l1.085.543V22.5z'
    />
  </svg>
);

const fullScreen = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 16 16'
    width={width || 16}
    height={height || 16}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g stroke={color || '#930000'}>
      <path fill='#fff' d='M.5.5h14.642v15H.5z' />
      <path fill={color || '#930000'} d='M.5.979h14.642v2.905H.5z' />
    </g>
  </svg>
);

export {
  file,
  fuel,
  filter,
  fullScreen,
  fleetsOnMap,
  folderClosed,
  folderOpened,
};

