/* eslint-disable max-len */
import React from 'react';
//////////////////////////////////////////////////

const quickbook = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 16 16'
    width={width || 16}
    height={height || 16}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#930000'}
      d='M.06 8.028A7.95 7.95 0 0 1 8.007.08a7.95 7.95 0 0 1 7.948 7.948 7.95 7.95 0 0 1-7.948 7.948A7.95 7.95 0 0 1 .06 8.028'
    />
    <path fill='#fff' d='M10.657 4.938h-.442v1.148h.442c1.071 0 1.943.871 1.943 1.943s-.871 1.942-1.943 1.942H9.596V3.967a1.15 1.15 0 0 0-1.148-1.148v8.301h2.209a3.09 3.09 0 1 0 0-6.182zm-8.391 3.09a3.09 3.09 0 0 0 3.091 3.091h.442V9.971h-.442c-1.071 0-1.943-.871-1.943-1.943s.871-1.943 1.943-1.943h1.061v6.005a1.15 1.15 0 0 0 1.148 1.148V4.937H5.358a3.09 3.09 0 0 0-3.091 3.091z' />
  </svg>
);

export {
  quickbook,
};
