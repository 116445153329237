/* eslint-disable max-len */
import React from 'react';
//////////////////////////////////////////////////

const scorecards = (color: any, width: any, height: any) => (
  <svg
    viewBox='0 0 40 40'
    width={width || 40}
    height={height || 40}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#474747'}
      d='M0 5.625v3.906h20 20V5.625 1.719H20 0v3.906zm0 12.227v5.664l9.125-.016 9.117-.023.023-5.649.016-5.641h-9.14H0v5.664zm20.875-.024l.023 5.649 9.555.023 9.547.016v-5.664-5.664h-9.57-9.57l.016 5.641zM0 32.188v6.094h9.141 9.141v-6.094-6.094H9.141 0v6.094zm20.859 0v6.094h9.57H40v-6.094-6.094h-9.57-9.57v6.094z'
    />
  </svg>
);

const search = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 14 14'
    width={width || 14}
    height={height || 14}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#930000'}
      d='M10.706 10l3.316 3.316-.706.706L10 10.706l.706-.706zM6 12A6 6 0 1 1 6 0a6 6 0 0 1 0 12zm0-1A5 5 0 1 0 6 1a5 5 0 0 0 0 10z'
    />
  </svg>
);

const searchDocument = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 22 20'
    width={width || 22}
    height={height || 20}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g fill={color || '#930000'}>
      <path d='M13.89 16.529c-3.024 0-5.593-1.939-6.496-4.618H3.757v-1.319h3.339a6.65 6.65 0 0 1-.049-.792c0-.359.029-.711.085-1.055H3.757V7.426h3.732c.259-.674.626-1.296 1.079-1.847H3.757V4.26h6.258c1.102-.749 2.438-1.188 3.876-1.188.874 0 1.71.164 2.479.459V.169H0v16.887c0 1.451 1.208 2.639 2.684 2.639h11.002c1.476 0 2.684-1.187 2.684-2.639v-.987c-.769.295-1.605.459-2.479.459z' />
      <path d='M18.467 12.8a5.32 5.32 0 0 0 .924-3c0-2.987-2.463-5.409-5.501-5.409S8.388 6.813 8.388 9.8s2.462 5.409 5.501 5.409a5.53 5.53 0 0 0 3.279-1.073l3.366 3.31 1.328-1.306-3.396-3.34zm-4.577 1.09c-2.293 0-4.159-1.835-4.159-4.09s1.866-4.09 4.159-4.09 4.159 1.835 4.159 4.09-1.865 4.09-4.159 4.09z' />
    </g>
  </svg>
);

const searchUser = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 21 20'
    width={width || 21}
    height={height || 20}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#353535'}
      d='M.146 17.665h8.419 7.771c-.727-3.345-1.379-5.406-1.957-6.181s-1.864-1.472-3.86-2.088H8.241 5.933c-1.782.459-3.12 1.155-4.011 2.088S.438 14.477.146 17.665z'
    />
    <ellipse
      ry='5'
      rx='5.022'
      cy='14.091'
      cx='14.152'
      fill='#f3f3f3'
    />
    <path
      fill={color || '#353535'}
      d='M17.797 16.441a4.06 4.06 0 0 0 .711-2.298c0-2.288-1.895-4.143-4.232-4.143s-4.232 1.855-4.232 4.143 1.894 4.144 4.232 4.144a4.27 4.27 0 0 0 2.523-.822L19.388 20l1.022-1.001-2.613-2.558zm-3.521.835c-1.764 0-3.2-1.405-3.2-3.133s1.435-3.133 3.2-3.133 3.2 1.405 3.2 3.133-1.435 3.133-3.2 3.133zM8.137 9.133c3.191.024 5.731-8.516.237-8.535s-3.428 8.511-.237 8.535z'
    />
  </svg>
);

const sendMessadeArrow = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 18 16'
    width={width || 18}
    height={height || 16}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#005395'}
      d='M.608 15.2L17.4 8 .608.8.6 6.4l12 1.6-12 1.6z'
    />
  </svg>
);

const serviceVendorInvoice = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 16 16'
    width={width || 16}
    height={height || 16}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g fill='none'>
      <path
        fill={color || '#930000'}
        d='M10.359 6.062c.133-.34.204-.785.204-1.379C10.563 1.666 8.81 0 5.633 0S.705 1.666.705 4.683c0 .594.072 1.039.205 1.38-.556.382-.91.865-.91 1.459 0 .76.265 1.032.876 1.095.216 3.05 2.258 5.444 4.758 5.444s4.541-2.394 4.758-5.444c.61-.063.875-.336.875-1.094 0-.595-.354-1.078-.908-1.462zM5.634.703c2.803 0 4.225 1.34 4.225 3.98 0 .458-.04.782-.119 1.026-1.15-.537-2.754-.787-4.106-.787s-2.956.25-4.107.787c-.078-.244-.119-.568-.119-1.026 0-2.64 1.422-3.98 4.226-3.98zm0 12.656c-2.111 0-3.843-2.08-4.054-4.735l4.054-.233c1.842 0 3.158.185 4.053.233-.21 2.654-1.942 4.735-4.053 4.735zm4.892-5.484c-.016.01-.116.057-.469.057-.318 0-.725-.036-1.242-.08a34.377 34.377 0 0 0-3.181-.164 34.22 34.22 0 0 0-3.181.163l-1.243.081c-.346 0-.448-.045-.457-.045-.001 0-.049-.079-.049-.363 0-1.12 2.598-1.899 4.93-1.899s4.93.78 4.93 1.899c0 .261-.041.349-.038.351zM6.988 2.096H4.193a.699.699 0 0 0-.7.699v.699a.7.7 0 0 0 .7.699h2.795a.699.699 0 0 0 .698-.7v-.698a.699.699 0 0 0-.698-.699zm0 1.398H4.193v-.699h2.795v.699z'
      />
      <circle
        r='4.219'
        cx='11.219'
        cy='11.219'
        strokeWidth='.5'
        stroke={color || '#930000'}
      />
      <path
        fill={color || '#930000'}
        d='M11.5 9.125a.75.75 0 0 1 .75.75.375.375 0 1 0 .75 0 1.5 1.5 0 0 0-1.125-1.447v-.053a.375.375 0 1 0-.75 0v.053A1.498 1.498 0 0 0 10 9.875a1.5 1.5 0 0 0 1.5 1.5.75.75 0 1 1-.75.75.375.375 0 1 0-.75 0 1.5 1.5 0 0 0 1.125 1.447v.053a.375.375 0 1 0 .75 0v-.053A1.498 1.498 0 0 0 13 12.125a1.5 1.5 0 0 0-1.5-1.5.75.75 0 0 1 0-1.5z'
      />
    </g>
  </svg>
);

const showTreeIcon = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 17 20'
    width={width || 17}
    height={height || 20}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g
      fill='none'
      strokeWidth='.8'
      stroke={color || '#930000'}
    >
      <rect x='8.889' y='5.556' rx='.5' width='4.444' height='3.333' />
      <rect x='4.444' rx='.5' width='4.444' height='3.333' />
      <rect x='12.222' y='11.111' rx='.5' width='4.444' height='3.333' />
      <rect x='4.444' y='16.667' rx='.5' width='4.444' height='3.333' />
      <path d='M4.044 1.715H1.496a.6.6 0 0 0-.6.6V18a.6.6 0 0 0 .6.6h2.548V1.715zM6.667 3.333v3.06a.8.8 0 0 0 .8.8h1.362M9.94 8.889v3.06a.8.8 0 0 0 .8.8h1.423' />
    </g>
  </svg>
);

const simpleFilter = (color: string, width: any, height: any) => (
  <svg
    width={width || 16}
    height={height || 16}
    viewBox='0 0 403 403'
  >
    <path
      fill={color || '#2073a1'}
      d='M400.858 11.427C397.617 4.006 392.008.295 384.004.291H18.564c-7.993 0-13.61 3.715-16.846 11.136-3.234 7.801-1.903 14.467 3.999 19.985l140.757 140.753V310.92c0 4.955 1.809 9.232 5.424 12.854l73.085 73.083c3.429 3.614 7.71 5.428 12.851 5.428 2.282 0 4.66-.479 7.135-1.43 7.426-3.238 11.14-8.851 11.14-16.845V172.166L396.861 31.413c5.904-5.518 7.232-12.182 3.997-19.986z'
    />
  </svg>
);

const sortByDate = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 25 21'
    width={width || 25}
    height={height || 21}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#930000'}
      d='M18.089 1.319h-3.332V.468c0-.256-.202-.464-.451-.464s-.451.208-.451.464v.851h-2.229V.468c0-.256-.202-.464-.451-.464s-.451.208-.451.464v.851h-2.23V.468c0-.256-.202-.464-.451-.464s-.451.208-.451.464v.851h-2.23V.468c0-.256-.202-.464-.451-.464S4.46.212 4.46.468v.851H1.128C.506 1.319 0 1.839 0 2.479v14.186c0 .639.506 1.16 1.128 1.16h16.961c.622 0 1.128-.52 1.128-1.16V2.479c0-.639-.506-1.16-1.128-1.16zm.226 15.346c0 .128-.101.232-.226.232H1.128a.23.23 0 0 1-.226-.232V6.371h15.999c.249 0 .451-.208.451-.464s-.202-.464-.451-.464H.902V2.479c0-.128.101-.232.226-.232H4.46v.851c0 .256.202.464.451.464s.451-.208.451-.464v-.851h2.229v.851c0 .256.202.464.451.464s.451-.208.451-.464v-.851h2.229v.851c0 .256.202.464.451.464s.451-.208.451-.464v-.851h2.229v.851c0 .256.202.464.451.464s.451-.208.451-.464v-.851h3.332a.23.23 0 0 1 .226.232v14.186h0zm-2.809-8.481h-2.949-2.949-2.949H3.71c-.224 0-.406.187-.406.417v3.032 3.032c0 .231.182.417.406.417h2.949 2.949 2.949 2.949c.224 0 .406-.187.406-.417v-3.032-3.032c0-.231-.182-.417-.406-.417zm-9.253 6.064H4.116v-2.197h2.137v2.197zm0-3.032H4.116V9.019h2.137v2.197zm2.949 3.032H7.065v-2.197h2.137v2.197zm0-3.032H7.065V9.019h2.137v2.197zm2.949 3.032h-2.137v-2.197h2.137v2.197zm0-3.032h-2.137V9.019h2.137v2.197zm2.949 3.032h-2.137v-2.197H15.1v2.197zm0-3.032h-2.137V9.019H15.1v2.197z'
    />
    <path
      fill='#fff'
      strokeWidth='0.7'
      stroke={color || '#930000'}
      d='M23.98 14.851c0 2.949-2.419 5.34-5.404 5.34s-5.404-2.391-5.404-5.34 2.42-5.34 5.404-5.34 5.404 2.391 5.404 5.34z'
    />
    <path
      fill={color || '#930000'}
      d='M21.384 14.617l-1.878-1.878c-.115-.115-.3-.115-.415 0s-.115.3 0 .415l1.377 1.377h-5.016c-.162 0-.293.131-.293.293s.131.293.293.293h5.016l-1.377 1.377c-.115.115-.115.3 0 .415.057.057.132.086.208.086s.15-.029.208-.086l1.878-1.878c.115-.115.115-.3 0-.415z'
    />
  </svg>
);

const speedometer = (color: string, width: any, height: any) => (
  <svg
    width={width || 15}
    height={height || 15}
    viewBox='0 0 204 204'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g>
      <g
        strokeWidth='4'
        fillRule='nonzero'
        fill={color || '#fff'}
        stroke={color || '#fff'}
      >
        <path
          d='M99.682,117.637l0.075,1.131c0.186,3.139,2.806,5.597,5.962,5.597c3.289,0,5.97-2.681,5.97-5.984
          c0-3.285-2.67-5.955-5.959-5.955h-1.17L82.439,96.157L99.682,117.637z'
        />
        <path
          d='M33.323,41.218l2.738-2.527C51.9,24.129,71.477,15.357,92.681,13.342l3.912-0.372l0.014,24.483
          h6.277V12.916l3.733,0.179c23.492,1.092,45.788,10.608,62.773,26.809l2.641,2.516l-15.339,15.378l4.452,4.441l15.153-15.164
          l2.487,3.164c9.996,12.705,16.72,27.865,19.437,43.837l0.705,4.166l-21.101,0.014v6.292h21.641l0.172,4.32
          c0.036,0.551,0.072,1.092,0.072,1.653c0,17.114-4.559,34.01-13.192,48.858l-2.33,4.008l-16.788-16.77l-4.434,4.445l14.595,14.598
          h10.364c10.601-16.459,16.194-35.474,16.194-55.143c0-56.263-45.788-102.047-102.065-102.047C45.781,8.471,0,54.255,0,110.518
          c0,19.691,5.601,38.705,16.198,55.143h10.579l14.902-13.02l-4.13-4.738L19.92,163.314l-2.187-3.729
          c-8.718-14.92-13.32-31.877-13.32-49.066c0-0.562,0.036-1.102,0.082-1.653l0.165-4.309l20.872-0.021v-6.292H5.189l0.705-4.173
          c2.827-16.663,9.935-32.321,20.528-45.283l2.498-3.056L43.92,60.715l0.487-0.476l0.505-0.523l3.454-3.45L33.323,41.218z'
        />
        <rect x='58.049' y='188.967' width='88.025' height='6.682' />
      </g>
    </g>
  </svg>
);

const stopMarker = (color: string, width: any, height: any) => (
  <svg
    width={width || 15}
    height={height || 15}
    viewBox='0 0 122.88 122.88'
    xmlns='http://www.w3.org/2000/svg'
  >
    <polygon
      fill={color || '#d92d27'}
      points='100.64 22.24 84.39 5.99 38.47 6 5.99 38.49 6 84.41 38.49 116.89 84.41 116.88 116.89 84.39 116.88 38.47 100.64 22.24 100.64 22.24'
    />
    <text x='12' y='75' fontSize={1.75 * width} fontWeight='bold' fill='#fff'>STOP</text>
  </svg>
);

const switchCompany = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 15 15'
    width={width || 15}
    height={height || 15}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g fill={color || '#930000'}>
      <path d='M5.603 10.49a.48.48 0 0 0-.645.087c-.768.948-1.938 1.491-3.21 1.491H.48c-.256 0-.46.191-.46.43s.204.429.46.429h1.273a5.17 5.17 0 0 0 2.237-.5 4.87 4.87 0 0 0 1.706-1.335.412.412 0 0 0-.092-.601zm1.299-6.035a.487.487 0 0 0 .648-.077c.768-.922 1.924-1.45 3.172-1.45H13.4l-1.43 1.333a.408.408 0 0 0 0 .607.48.48 0 0 0 .325.127.466.466 0 0 0 .324-.127l2.217-2.067a.408.408 0 0 0 0-.607L12.619.126a.483.483 0 0 0-.652 0 .408.408 0 0 0 0 .608l1.43 1.332h-2.678c-1.535 0-2.957.65-3.902 1.785a.417.417 0 0 0 .085.604z' />
      <path d='M12.622 10.128a.483.483 0 0 0-.651 0 .408.408 0 0 0 0 .608l1.429 1.332h-2.678c-2.213 0-4.014-1.679-4.014-3.743V6.671c0-2.537-2.214-4.601-4.936-4.601H.5c-.256 0-.46.19-.46.429s.204.43.46.43h1.272c2.214 0 4.015 1.678 4.015 3.742v1.654c0 2.538 2.213 4.602 4.935 4.602H13.4l-1.43 1.333a.408.408 0 0 0 0 .607.48.48 0 0 0 .325.127.466.466 0 0 0 .324-.127l2.217-2.067a.408.408 0 0 0 0-.608l-2.214-2.064z' />
    </g>
  </svg>
);

const symbol = (symbol: string, color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 15 15'
    width={width || 15}
    height={height || 15}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g>
      <text x='2' y='12' fill={color}>{symbol}</text>
    </g>
  </svg>
);

export {
search,
symbol,
scorecards,
searchUser,
sortByDate,
stopMarker,
speedometer,
showTreeIcon,
simpleFilter,
switchCompany,
searchDocument,
sendMessadeArrow,
serviceVendorInvoice,
};

