/* eslint-disable max-len */
import React from 'react';
//////////////////////////////////////////////////

const onMap = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 15 15'
    width={width || 15}
    height={height || 15}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#930000'}
      d='M12.314 9.25h-1.756l.747-1.1c1.412-1.918 1.207-5.07-.436-6.746A4.626 4.626 0 0 0 7.543 0a4.627 4.627 0 0 0-3.325 1.404C2.574 3.08 2.37 6.232 3.776 8.142l.752 1.108H2.686L0 15h15l-2.686-5.75zM7.587 3c.946 0 1.716.785 1.716 1.75S8.533 6.5 7.587 6.5 5.87 5.715 5.87 4.75 6.64 3 7.587 3zM2.996 9.75h1.872l2.675 3.94 2.676-3.94h1.785l2.22 4.75H.776l2.219-4.75z'
    />
  </svg>
);

export {
  onMap,
};
