/* eslint-disable max-len */
import React from 'react';
//////////////////////////////////////////////////

const xls = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 15 15'
    width={width || 15}
    height={height || 15}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#353535'}
      d='M14.996 6.856a.305.305 0 0 0-.017-.083l-.01-.03a.309.309 0 0 0-.06-.088v-.001L13.124 4.87V3.125c0-.007-.003-.013-.004-.02a.31.31 0 0 0-.016-.08l-.01-.03a.312.312 0 0 0-.062-.09L10.221.091A.314.314 0 0 0 10.13.03c-.01-.005-.02-.008-.03-.011a.316.316 0 0 0-.079-.016C10.014.004 10.007 0 10 0H2.187a.312.312 0 0 0-.312.313V4.87L.092 6.654l-.001.001a.304.304 0 0 0-.06.089l-.01.03a.312.312 0 0 0-.017.082L0 12.813c0 .172.14.312.313.312h1.562v1.563c0 .172.14.312.313.312h10.624c.173 0 .313-.14.313-.313v-1.562h1.563c.172 0 .312-.14.312-.313V6.875zm-1.063-.293h-.808v-.809l.808.809zm-1.875-3.75h-1.745V1.067l1.745 1.746zM2.5.624h7.188v2.5c0 .172.14.313.312.313h2.5v3.125h-10V.625zm-.625 5.13v.808h-.808l.808-.809zm10.625 8.62h-10v-1.25h10v1.25zm1.875-1.875H.625V7.187h13.75V12.5zM7.617 8.114h-.643v3.761h1.679v-.59H7.617zm2.077.6a.436.436 0 0 1 .154-.093.648.648 0 0 1 .696.22l.35-.456a.989.989 0 0 0-.379-.274 1.338 1.338 0 0 0-.9-.027.982.982 0 0 0-.588.553 1.25 1.25 0 0 0-.093.502c0 .156.02.29.061.401a.97.97 0 0 0 .165.292 1.09 1.09 0 0 0 .24.215 2.44 2.44 0 0 0 .289.165c.163.082.297.17.403.266s.16.225.16.388-.045.295-.133.387a.444.444 0 0 1-.335.139.795.795 0 0 1-.353-.085.88.88 0 0 1-.295-.234l-.345.467c.106.124.247.227.425.308a1.36 1.36 0 0 0 .579.123 1.15 1.15 0 0 0 .414-.075.961.961 0 0 0 .343-.22c.097-.098.175-.217.234-.359a1.28 1.28 0 0 0 .087-.489c0-.159-.025-.297-.074-.414a1.055 1.055 0 0 0-.191-.303 1.27 1.27 0 0 0-.26-.218 3.226 3.226 0 0 0-.282-.159 1.666 1.666 0 0 1-.378-.247c-.095-.087-.143-.21-.143-.37 0-.091.013-.171.04-.238a.442.442 0 0 1 .11-.165zm-3.219-.6H5.82L5.27 9.41l-.56-1.296h-.664l.85 1.78-.94 1.981h.664l.632-1.44.627 1.44h.685l-.946-1.982z'
    />
  </svg>
);

export {
  xls,
};
