/* eslint-disable max-len */
import React from 'react';
//////////////////////////////////////////////////

const importDocument = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 13 16'
    width={width || 13}
    height={height || 16}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      strokeWidth='.3'
      fill={color || '#fff'}
      stroke={color || '#fff'}
      d='M7.93 6.91a.224.224 0 0 0-.227-.222H5.914c-.125 0-.226.1-.226.222v2.278H4.253a.227.227 0 0 0-.21.137.218.218 0 0 0 .05.242l2.556 2.5a.228.228 0 0 0 .32 0l2.556-2.5a.218.218 0 0 0 .05-.242.227.227 0 0 0-.21-.137H7.93V6.91zm.887 2.722L6.81 11.597 4.8 9.632h1.114c.126 0 .227-.1.227-.222V7.132h1.336V9.41c0 .123.101.222.226.222h1.114zm3.719-5.042L8.928 1.067A.243.243 0 0 0 8.76 1H1.719C1.336 1 1 1.305 1 1.68v13.156c0 .19.098.368.235.503a.785.785 0 0 0 .537.208h10.127a.73.73 0 0 0 .503-.198.68.68 0 0 0 .216-.478V4.751c0-.06-.039-.118-.082-.16zM8.687 1.48l3.226 3.157H8.679l.008-3.156zm3.466 13.391c0 .058-.038.116-.08.157a.262.262 0 0 1-.174.065H1.772a.32.32 0 0 1-.208-.075c-.05-.05-.1-.114-.1-.182V1.68c0-.124.126-.225.253-.225h6.496l-.007 3.407a.23.23 0 0 0 .07.162.242.242 0 0 0 .168.067h3.709v9.78z'
    />
  </svg>
);

const importDocument2 = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 14 14'
    width={width || 14}
    height={height || 14}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#fff'}
      d='M6.623.041a1.83 1.83 0 0 0-1.225 1.014c-.145.325-.148.372-.148 2.368l-.027 1.92a.26.26 0 0 1-.107.112c-.079.038-.129.036-.82-.082-.823-.137-.864-.139-1.009-.079a.59.59 0 0 0-.306.788c.071.145 3.593 4.246 3.716 4.329.172.115.435.115.61 0 .12-.082 3.642-4.186 3.713-4.329a.59.59 0 0 0-.306-.788c-.145-.06-.186-.057-1.009.079-.681.115-.744.12-.818.082-.044-.022-.088-.057-.101-.074s-.027-.921-.036-2.004l-.014-1.969-.074-.2C8.561.927 8.438.733 8.23.522A1.63 1.63 0 0 0 7.068.011c-.175-.005-.336.003-.446.03zM2.01 11.714c-.323.101-.591.336-.73.637a1.17 1.17 0 0 0 .109 1.154c.142.186.271.29.487.391l.189.09 4.854.008 4.988-.022c.394-.085.736-.391.864-.782a1.16 1.16 0 0 0-.049-.839c-.112-.243-.328-.459-.572-.574l-.186-.087-4.922-.006c-3.924-.003-4.944.003-5.031.03z'
    />
  </svg>
);

const inService = (color: string, width: any, height: any) => (
  <svg
    width={width || 300}
    viewBox='0 0 149 145'
    height={height || 300}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#fff'}
      d='M97.783 66.317c3.089-2.061 6.733-2.939 10.3-2.539l40.633-40.644L125.961.378 85.322 41.022c.411 3.611-.5 7.3-2.644 10.428l15.106 14.867zm-70.883 49.5l-1.472-1.472-7.706 6.228-12.978 20.45 3.322 3.322 20.467-12.978 6.222-7.683-1.478-1.494 25.844-25.85-6.422-6.333zM62.244 44.15c2.983-10.667.278-22.589-8.094-30.978-8.344-8.311-20.128-11.039-30.733-8.161l17.961 17.956-4.711 17.589-17.611 4.711L1.1 27.322c-2.867 10.6-.144 22.383 8.183 30.717 8.728 8.733 21.306 11.294 32.306 7.717l.1.1 73.883 73.883a14.96 14.96 0 0 0 10.583 4.394c3.833 0 7.656-1.478 10.589-4.394 5.839-5.828 5.839-15.3 0-21.172l-74.5-74.417zm65.2 93.078c-3.144 0-5.694-2.561-5.694-5.717 0-3.167 2.55-5.717 5.694-5.717 3.167 0 5.728 2.55 5.728 5.717.006 3.156-2.556 5.717-5.728 5.717z'
    />
  </svg>
);

const invoice = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 15 15'
    width={width || 15}
    height={height || 15}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#930000'}
      d='M12 9.5a.5.5 0 0 1 .5.5.25.25 0 1 0 .5 0 .999.999 0 0 0-.75-.964V9a.25.25 0 1 0-.5 0v.036A.999.999 0 0 0 12 11a.5.5 0 1 1-.5.5.25.25 0 1 0-.5 0 1 1 0 0 0 .75.965v.035a.25.25 0 1 0 .5 0v-.035A.999.999 0 0 0 12 10.5a.5.5 0 0 1 0-1zM13.75 2H5a.25.25 0 0 0 0 .5h8.75a.75.75 0 0 1 .75.75V14.5h-10V3.25a1.25 1.25 0 1 0-2.5 0v8.5a.75.75 0 0 1-1.5 0V.5h10v.75a.25.25 0 1 0 .5 0v-1a.25.25 0 0 0-.25-.25H.25A.25.25 0 0 0 0 .25v11.5a1.25 1.25 0 1 0 2.5 0v-8.5a.75.75 0 0 1 1.5 0v9.25H2.75a.25.25 0 0 0 0 .5H4v1.75a.25.25 0 0 0 .25.25h10.5a.25.25 0 0 0 .25-.25V3.25A1.25 1.25 0 0 0 13.75 2z'
    />
  </svg>
);

const invoice2 = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 14 14'
    width={width || 14}
    height={height || 14}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#fff'}
      d='M2.01.038c-.101.044-.191.142-.23.243s-.041 12.075-.003 12.214c.019.074.191.26.716.785.935.93.848.919 1.594.172l.489-.492.509.503c.725.719.697.719 1.43-.005L7 12.977l.487.481c.73.725.703.725 1.427.005l.509-.503.489.492c.746.747.659.757 1.594-.172l.716-.787c.019-.066.025-2.269.022-6.158L12.236.276l-.057-.074c-.03-.041-.09-.101-.131-.131l-.074-.057L7.033.008c-4.077-.005-4.955 0-5.023.03zm5.206 2.229c.153.109.18.178.189.492l.011.298.298.011c.331.014.391.038.511.213.115.17.057.421-.123.539-.066.044-.161.052-.801.063l-.727.014-.118.077c-.126.085-.221.249-.221.388a.6.6 0 0 0 .159.35c.112.101.249.126.675.129.591 0 .842.085 1.135.38a1.28 1.28 0 0 1-.016 1.821c-.186.183-.396.29-.7.353l-.077.016v.276c0 .314-.038.427-.175.525a.47.47 0 0 1-.47 0c-.137-.098-.175-.211-.175-.525V7.41h-.276c-.314 0-.427-.038-.525-.175a.47.47 0 0 1 0-.47c.118-.164.175-.175.921-.175.741 0 .798-.011.924-.159.202-.241.148-.566-.118-.714-.088-.052-.148-.057-.492-.057-.235 0-.468-.016-.58-.041-.52-.109-.927-.544-1.012-1.077-.098-.645.355-1.299.998-1.43l.159-.033v-.268c0-.293.033-.405.142-.509.131-.12.347-.137.484-.036zm2.177 7.448c.123.077.186.178.2.32s-.03.246-.145.355l-.074.068H6.997 4.621l-.101-.104c-.098-.096-.104-.112-.104-.273 0-.23.093-.353.301-.405.046-.011 1.096-.019 2.338-.016l2.338.055z'
    />
  </svg>
);

const itemBox = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 16 16'
    width={width || 16}
    height={height || 16}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#4c4c4c'}
      d='M9.25 8.126l-.796.415V16l7.173-3.728V4.813zm1.299-6.689L7.79 0 .417 3.831l2.762 1.437zm4.61 2.394l-3.179-1.63-7.37 3.831.421.195L7.79 7.661l2.744-1.424zM4.053 8.773l-1.32-.61V6.264L.011 4.853v7.406l7.122 3.701V8.554l-3.08-1.596z'
    />
  </svg>
);

export {
  invoice,
  itemBox,
  invoice2,
  inService,
  importDocument,
  importDocument2,
};
